import React, {useCallback, useState} from 'react';
import { DropDownFilterProps } from './DropDownFilter.props';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Checkbox,
  InputAdornment,
  ListItem,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import './DropDownFilter.css';
import './styles/mobile.css';

const DropDownFilter: React.FC<DropDownFilterProps> = ({
  arrList =[],
  label,
  type,
  onSelectItem,
  searchFieldPlaceholder = 'Search...',
  selectedItems = [],
  clearFilerSelection,
  isOpen,
  setIsOpen,
  defaultArrListToShowSearchField=[],
  disable
}) => {
  const initDropdown = { category: null, priority: null, scans: null };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [arrayTotal, setArrayTotal] = useState<any>(arrList);

  const onSearch = (searchText: string, array:any[], type: string) => {
    const filteredOptions = array.filter((item: any) => {
      if (searchText.length !== 0) {
        return item.content.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      } else {
        return true;
      }
    });
    if (type) {
      setArrayTotal([...filteredOptions]);
    }
  };

  const handleChange = useCallback(
    (event: SelectChangeEvent<any>, typeChangeOfSelect: string) => {
      const {
        target: { value, name = typeChangeOfSelect }
      } = event;
      let mainValue = value;

      const checkUndefineData = value.includes(undefined)
      if(checkUndefineData){
        mainValue = mainValue.filter((x:unknown) => x !== undefined)
        return
      }
      if(Array.isArray(mainValue) && mainValue[0]) {
        onSelectItem(mainValue, name);
      } else {
        onSelectItem([], name);
      }
    },
    [onSelectItem]
  );

  const clearSelectedCheckbox = (type: string) => {
    clearFilerSelection(type);
    onSelectItem([], type);
    setIsOpen(initDropdown);
  };

  const handleClose = () => {
    setIsOpen(initDropdown);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpen({
      ...initDropdown,
      [type]: event.currentTarget
    });
    onSearch('', arrList,type)
  };

  const checkSelected = selectedItems[type] ? selectedItems[type].length : 0;
  const colons = checkSelected > 0 ? ':' : '';
  return (
    <Stack>
      {isMobile && <Typography>{type}</Typography>}
      <FormControl className={checkSelected ? 'has-selected' : ''} sx={{ m: 1 }}>
        <Select
          data-testid={type + '-multiple-checkbox'}
          labelId={type + '-checkbox-label'}
          className={'dropdown-selected'}
          // id={type + "-multiple-checkbox"}
          onOpen={(e: any) => handleOpen(e)}
          onClose={() => handleClose()}
          open={Boolean(isOpen[type])}
          autoWidth={true}
          multiple
          value={selectedItems[type] || []}
          onChange={(e) => {
            handleChange(e, type);
          }}
          displayEmpty
          disabled={disable}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return (
                <span className="label-placeholder">{isMobile ? 'Select' : label + colons}</span>
              );
            }
            return (
              <span>
                <span style={{ fontSize: '14px', fontWeight: 700 }}>{label}</span>: {selected[0]}{' '}
                {selected.length > 1 && (
                  <span className="selected-total">+{selected.length - 1}</span>
                )}
              </span>
            );
          }}
          MenuProps={{
            id: `${type + '-menu'}`,
            anchorEl: isOpen[type],
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            style: { zIndex: 1 },
            className: 'dropdown-menu-list',
            autoFocus: false,
          }}
          sx={{
            backgroundColor: isMobile ? (checkSelected ? 'transparent' : 'white') : ''
          }}
        >
          {defaultArrListToShowSearchField.length > 7 && (
            <ListItem className={'search-input-item'} style={{ backgroundColor: 'unset' }}>
              <OutlinedInput
                className={'search-outline-input'}
                data-testid={'search-input-item'}
                fullWidth
                placeholder={searchFieldPlaceholder || 'Search'}
                onChange={(e) => {
                  onSearch(e.target.value, arrList, type)
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </ListItem>
          )}
          {arrayTotal.map((item:any) => {
            return (
              <MenuItem key={'filter-item-'+item.id} value={item.content} className="filter-item">
                <FormControlLabel
                  control={
                    <Checkbox
                      className={'checkbox_' + type}
                      id={type + '-' + item.id}
                      // Resolve Material-UI: A component is changing the uncontrolled checked state of SwitchBase to be controlled
                      checked={
                        !!(selectedItems[type] && selectedItems[type]?.includes(item.content))
                      }
                    />
                  }
                  label={item.content}
                />
              </MenuItem>
            );
          })}
          {checkSelected > 0 && (
            <ListItem
              data-testid="clear-selected"
              className="clear-selected"
              style={{ backgroundColor: 'unset' }}
              onClick={() => clearSelectedCheckbox(type)}
            >
              <button
                style={{ border: 'none', background: 'none' }}
                onClick={() => clearSelectedCheckbox(type)}
                data-testid="clear-selected-button"
              >
                <p
                  className={`text-style`}
                  style={{ color: '#467599', fontWeight: 600, fontSize: '16px', margin: '0' }}
                >
                  {'Clear selected items'}
                </p>
              </button>
            </ListItem>
          )}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default DropDownFilter;

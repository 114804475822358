import React from 'react';
import {
  Box,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { TabsHeaderProps } from './TabsHeader.props';
import './TabsHeader.css';
import './styles/mobile.css';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

export const TabsHeader: React.FC<TabsHeaderProps> = ({
  defaultValue,
  data = [],
  onChange,
  isDropdown,
  onClick,
  renderTabs
}) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const options = data?.map((obj, index) => ({
    label: obj.label,
    value: index
  }));

  const MobileDropdown: React.FC = () => (
    <Box
      display="flex"
      justifyContent="center"
      padding="21px 0px 13px"
      borderBottom="2px #E0E0E0 solid"
    >
      <Select
        value={value}
        onChange={(event) => setValue(event.target.value)}
        variant="standard"
        className="mobile-dropdown"
        data-testid="tab-mobile-dropdown"
      >
        {options?.map((option, index: number) => (
          <MenuItem
            data-testid="item-mobile-dropdown"
            key={option.value + index}
            value={option.value}
            onClick={() => onClick?.(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  return (
    <Box className={'tabs-header'} sx={{ width: '100%' }}>
      {isDropdown ? (
        <MobileDropdown />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box className={'tabs-header-box'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {renderTabs?.({ data, handleChange, value }) ?? (
              <Tabs
                data-testid="header-tab"
                className={'tabs-header-box-tabs'}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {data.map((item: any, index: number) => (
                  <Tab
                    key={index}
                    icon={
                      Number.isInteger(item?.number) ? <span className={'tab-icon'}>{item?.number} </span> : undefined
                    }
                    iconPosition="end"
                    label={item?.label}
                    onClick={() => onClick?.(index)}
                    className="tabs-header-tab"
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            )}
          </Box>
        </Box>
      )}
      {data.map((item: any, index: number) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {item.component}
          </TabPanel>
        );
      })}
    </Box>
  );
};

import React, { FC } from 'react';
import './CourseCatalogueCard.css';
import { Button, Card, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import {CourseCatalogueCardProps} from "./CourseCatalogueCard.props";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import {styled} from "@mui/material/styles";
import PassedIcon from '../../assets/images/passed-icon.svg';
import { useNavigate } from 'react-router-dom';
import { parseImgURL } from '../../utils/util';
import OutStandingIcon from '../../assets/images/outstanding-icon.svg';
import AssignedIcon from '../../assets/images/assigned-icon.svg';
import moment from "moment";
import {convertUtcToTimezone} from "../../utils/dateTimeHelper";
import {FORMAT_DATE} from "../../constants/constants";

const CustomCard = styled(Card)({
  "& .appear-item": {
    display: "none"
  },
  "&:hover .appear-item": {
    display: "block"
  },
  "&:hover .card-catalogue-img":{
    maxHeight: '188px'
  }
});
export const CourseCatalogueCard: FC<CourseCatalogueCardProps> = ({
  title,
  subTitle,
  date,
  id,
  tp_id,
  thumbnailPath,
  status
}) => {
  const utcByTimezone = convertUtcToTimezone(date);
  const navigate = useNavigate();
  const checkStatusIcon=(status :string)=>{
    switch (status){
      case "Passed":
        return PassedIcon;
      case "Assigned":
        return AssignedIcon;
      case "Outstanding":
        return OutStandingIcon;
      default:
        return '';
    }
  }

  // const handleImageError = (e:any) => {
  //   e.target.onerror = null;
  //   // e.target.style.display = 'none'
  //   // e.target.src = "https://via.placeholder.com/150"
  //   e.target.src = OutStandingIcon
  // }

  return (
    <>
      <CustomCard className={'card-catalogue'}>
        <div className={'catalogue-icon-wrapper'} >
          <div className={'catalogue-icon'} >
            <img src={checkStatusIcon(status)} className={'catalogue-icon-status'} width={'35px'} height={'35px'} />
          </div>
        </div>
        <CardMedia
          className={'card-catalogue-img'}
          title={''}
          component={'img'}
          image={`${parseImgURL(
            thumbnailPath,
            tp_id
          )}`}
          // onError={handleImageError}
        />
        <CardContent className={'card-catalogue-content'}>
          {title.length > 23 ? <Tooltip
              title={title}
              placement="bottom"
              classes={{ popper: 'status-tooltip' }}
              className="status-icon"
              disableInteractive
              PopperProps={{
                sx: { marginLeft: '-15px !important', marginTop: '-11px !important' }
              }}
            >
              <Typography className={'card-catalogue-title multiline-ellipsis'}>
                {title}
              </Typography>
            </Tooltip>
            : <Typography className={'card-catalogue-title multiline-ellipsis'}>
              {title}
            </Typography>
          }
          <Typography variant={'body2'} className={'card-catalogue-subtitle multiline-ellipsis'}>
            {subTitle}
          </Typography>
          <Typography className={'card-catalogue-date'}>
            <InsertInvitationIcon
              sx={{ marginBottom: '-7px', marginRight: '5px',color:'#193051' }}
              className={'insert-invitation'}
            />
            {utcByTimezone ? moment(utcByTimezone).format(FORMAT_DATE.DATE_TIME_ROUND_TRIP) : ''}
          </Typography>
          <Button
            variant={'outlined'}
            data-testid={'btn-catalogue-overview'}
            className={'card-catalogue-button appear-item'}
            onClick={() => navigate(`/courses/catalogue-overview/${id}`,{
              state: {
                tabIndex: 1,
                subTabIndex:0
              }
            })}
          >
            Overview
          </Button>
        </CardContent>
      </CustomCard>
    </>
  );
};

import React from 'react';
import { Stack, Typography } from '@mui/material';

import './MobileDataTable.css';
import { MobileDataTableProps } from './MobileDataTable.props';

interface BasicCellProps {
  cellName: string;
  cellContent: string;
}

export const BasicCell: React.FC<BasicCellProps> = ({ cellName, cellContent }) => (
  <Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    className='mobile-basic-cell'
  >
    <Typography fontSize={12} fontWeight={600}>
      {cellName}
    </Typography>
    <Typography textAlign='end' fontSize={14}>{cellContent}</Typography>
  </Stack>
);

export function MobileDataTable<T>(props: MobileDataTableProps<T>) {
  const { data, cells } = props;

  return (
    <Stack overflow='scroll' height='65vh' className='mobile-data-table'>
      {data.map((item, index) => (
        <Stack key={'cell-group-' + index} className={'mobile-data-cell-group'}>{cells.map((cell,index) => (<span key={index}>{cell(item)}</span>))}</Stack>
      ))}
    </Stack>
  );
}

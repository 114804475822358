import React, { useEffect } from 'react';
import { OverviewCourseDetailProps } from './OverviewCourseDetail.props';
import { Box, Button, Stack } from '@mui/material';
import { LeftCardDetail } from '../../components/LeftCardDetail';
import { useDispatch } from 'react-redux';

import './OverviewCourseDetail.css';
import './styles/ipad.css';
import './styles/mobile.css';
import { useAppSelector } from '../../redux/hooks';
import {
  trainingCourseDetailEvents,
  trainingCourseDetailEventsDataSelector
} from '../../redux/trainingCourseDetailSlice';
import { getCurrentCellStore, handleStartCourse } from '../../utils/util';
import { TYPE_LETF_CARD_DETAIL } from '../../constants/constants';
import { useLocation } from "react-router-dom";
import ChangeWorkSpace from "../../hoc/changeWorkSpace";
import {LoadingPage} from "../../components/LoadingPage";

const OverviewCourseDetailComponent: React.FC<OverviewCourseDetailProps> = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const { courseDetails, isLoading } = useAppSelector(trainingCourseDetailEventsDataSelector);
  const currentCellStore = getCurrentCellStore();
  const pathSubstrings = pathname.split('/');
  const courseId = pathSubstrings[3];
  useEffect(() => {
    if (courseId) {
      dispatch(trainingCourseDetailEvents({
        student_id: currentCellStore?.student_id,
        campaign_id: courseId
      }));
    }
  }, [courseId]);

  return (
    <>
      {!isLoading && <Box
      className={'box-course-page-detail'}
      sx={{
        backgroundColor: '#ffffff',
        padding: '0 23px 30px',
      }}
    >
      <LeftCardDetail leftCardDetailsData={courseDetails} type={TYPE_LETF_CARD_DETAIL.GRADES} />
      <Stack direction={'row'} justifyContent='flex-end' alignItems='center' mt={'24px'}>
        <Button
          variant={'outlined'}
          data-testid={'btn-start-overview-course-detail-test'}
          className={'btn-detail-start-courses'}
          disabled={!courseDetails?.token_1 || !courseDetails?.token_2}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleStartCourse(courseDetails?.token_1, courseDetails?.token_2);
          }}
        >
          Start Course
        </Button>
      </Stack>
    </Box>}
    <LoadingPage open={isLoading} />
  </>
  );
};

export const OverviewCourseDetail = ChangeWorkSpace(OverviewCourseDetailComponent);
import React, { useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthenticatedStatus } from '../../utils/checkUserAuthenticated';
import { TimeoutHandlerProps } from './TimeoutHandler.props';
import { removeWorkSpace } from "../../redux/workSpaceSlice";
import { useDispatch } from "react-redux";
import {removeTrainingCoursesByDateEvent} from "../../redux/trainingCoursesByDateSlice";
import {removeTrainingCourseDetailEvent} from "../../redux/trainingCourseDetailSlice";

const TimeOutHandler: React.FC<TimeoutHandlerProps> = () => {
  let timer: any = undefined;
  const events = ['click', 'load', 'keydown'];
  let lastInteractionTime: any;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = AuthenticatedStatus();
  const eventHandler = () => {
    if (isAuthenticated) {
      lastInteractionTime = moment();
      if (timer) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      addEvents();
      eventHandler();
    }
    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, [isAuthenticated]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(async () => {
      const diff: any = moment.duration(moment().diff(moment(lastInteractionTime)));
      const timeOutInterval = 1200000;
      if (!isAuthenticated) {
        clearTimeout(timer);
      } else {
        if (diff._milliseconds < timeOutInterval) {
          startTimer();
        } else {
          removeEvents();
          clearTimeout(timer);
          localStorage.clear()
          dispatch(removeWorkSpace())
          dispatch(removeTrainingCoursesByDateEvent())
          dispatch(removeTrainingCourseDetailEvent())
          navigate('/timeout');
          await Auth.signOut();
        }
      }
    }, 10000);
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };
  return <div></div>;
};

export default TimeOutHandler;

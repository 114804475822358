import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IDataFilter } from '../components/SearchMenuBar';
import { getCourseCategories } from '../api/platform';

export interface CourseCategoriesState {
  error: any;
  isLoading: boolean;
  courseCategories: IDataFilter[];
}

const initialState: CourseCategoriesState = {
  courseCategories: [],
  error: undefined,
  isLoading: false
};

export const getCourseCategoriesEvent: any = createAsyncThunk(
  'getCourseCategoriesPlatform', async () => {
  const response = await getCourseCategories();
  return response.data ?? [];
});

const courseCategoriesSlice = createSlice({
  name: 'courseCategories',
  initialState,
  reducers: {
    // getCourseCategories: (state, action) => {
    //   state.isLoading = false;
    //   state.courseCategories = action.payload;
    //   state.error = undefined;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseCategoriesEvent.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });

    builder.addCase(getCourseCategoriesEvent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.courseCategories = action.payload.map((category: string, index: number) => ({
        id: index,
        content: category
      }));
      state.error = undefined;
    });

    builder.addCase(getCourseCategoriesEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.courseCategories = [];
      state.error = action.payload as string;
    });
  }
});
export const courseCategoriesDataSelector = (state: RootState) => state.courseCategoriesEvents;
export default courseCategoriesSlice.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { DateRangeParams, getListTrainingCoursesQuantity } from '../api/platform';
export interface ICoursesQuantityData {
  data: object;
  has_incomplete_date: string | null;
}
export interface TrainingCoursesEventsObjectState {
  coursesQuantityDataAll: ICoursesQuantityData;
  isLoadingCoursesQuantityAll: boolean;
  error?: string;
}

const initialState: TrainingCoursesEventsObjectState = {
  coursesQuantityDataAll: {
    data: {},
    has_incomplete_date: null,
  },
  isLoadingCoursesQuantityAll: false,
  error: ''
};

export const trainingCoursesQuantityAllEvents = createAsyncThunk(
  'get/trainingCoursesQuantityAllEvents',
  async (params: DateRangeParams) => {
    const response = await getListTrainingCoursesQuantity(params);
    return response
  }
);

export const trainingCoursesAllSlice = createSlice({
  name: 'trainingCoursesAllEvents',
  initialState,
  reducers: {
    setTrainingCoursesAllEventData: (state, action: PayloadAction<ICoursesQuantityData>) => {
      state.isLoadingCoursesQuantityAll = false;
      state.coursesQuantityDataAll = action.payload;
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(trainingCoursesQuantityAllEvents.pending, (state) => {
      state.isLoadingCoursesQuantityAll = true;
    });

    builder.addCase(
      trainingCoursesQuantityAllEvents.fulfilled,
      (state, action: PayloadAction<ICoursesQuantityData>) => {
        state.isLoadingCoursesQuantityAll = false;
        state.coursesQuantityDataAll = action.payload;
        state.error = '';
      }
    );

    builder.addCase(trainingCoursesQuantityAllEvents.rejected, (state, action) => {
      state.isLoadingCoursesQuantityAll = false;
      state.coursesQuantityDataAll = initialState.coursesQuantityDataAll;
      state.error = action.payload as string;
    });
  }
});

export const { setTrainingCoursesAllEventData } = trainingCoursesAllSlice.actions;

export const trainingCoursesQuantityAllEventsSelector = (state: RootState) => state.trainingCoursesAllEvents;

export default trainingCoursesAllSlice.reducer;

import React from 'react';
import { IconButton, MenuItem, Select } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import './CustomPagination.css';
import { CustomPaginationProps } from './CustomPagination.props';

export const CustomPagination: React.FC<CustomPaginationProps> = (props) => {
  const {
    rowsPerPageOptions,
    currentPage,
    totalPage,
    perPage,
    startPage,
    endPage,
    totalRows,
    setPerPage,
    setCurrentPage,
    onChangePage,
    onRowsPerPageChange
  } = props;
  const disableNext = totalPage < 1 || currentPage === totalPage;
  const disablePrevious = currentPage === 1;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onChangePage && onChangePage(page)
  };

  const handleRowsPerPageChange = (event: any) => {
    const pageValue = parseInt(event.target.value);
    setPerPage(pageValue);
    setCurrentPage(1);
    onRowsPerPageChange && onRowsPerPageChange(pageValue)
  };

  return (
    <nav className={'pagination-custom-wrapper'}>
      <div className="pagination-custom">
        <span className="pagination-row-per-pages" style={{ marginRight: 12 }}>
          Rows per page
        </span>
        <Select
          data-testid={'selector-test'}
          value={perPage}
          onChange={handleRowsPerPageChange}
          className={'pagination-selection'}
          MenuProps={{
            className:'pagination-selection-page',
          }}
        >
          {rowsPerPageOptions.map((page) => (
            <MenuItem
              data-testid={'select-option-test'}
              aria-valuenow={page}
              value={page}
              key={page}
            >
              {page}
            </MenuItem>
          ))}
        </Select>
        <span className={'pagination-total-rows'}>
          {startPage}-{endPage} of {totalRows}
        </span>

        <ul className={'pagination-numbers'}>
          <li>
            <IconButton
              data-testid={'btn-previous-test'}
              onClick={() => handlePageChange(1)}
              className='first-page'
              disabled={disablePrevious}
            >
              <FirstPageIcon sx={{ color: 'black' }} />
            </IconButton>
          </li>
          <li>
            <IconButton
              data-testid={'btn-first-test'}
              onClick={() => handlePageChange(currentPage - 1)}
              className='previous-page'
              disabled={disablePrevious}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </li>
          <li>
            <IconButton
              data-testid={'btn-next-test'}
              onClick={() => handlePageChange(currentPage + 1)}
              className='next-page'
              disabled={disableNext}
            >
              <KeyboardArrowRight />
            </IconButton>
          </li>
          <li>
            <IconButton
              data-testid={'btn-last-test'}
              onClick={() => handlePageChange(totalPage)}
              className='last-page'
              disabled={disableNext}
            >
              <LastPageIcon />
            </IconButton>
          </li>
        </ul>
      </div>
    </nav>
  );
};

import React from 'react';
import { Box, Button, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import avaDemo from '../../assets/images/avatar-demo.svg';
import './PrivatePolicy.css';
import './styles/mobile.css';
import { PrivatePolicyProps } from './PrivatePolicy.props';

export const PrivatePolicy: React.FC<PrivatePolicyProps> = ({ ...props }: PrivatePolicyProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <div className={'policy-box-wrapper'}>
      <Typography fontSize={isMobile ? 20 : '1.875rem'} className={'post-title'}>
        {props.title ?? ''}
      </Typography>
      <Box className={'post-wrapper-info'}>
        <img src={props.avatar ?? avaDemo} alt={'avatar Phish demo'} />
        <Box className={'post-info'}>
          <Typography>Posted by {props.author ?? ''}</Typography>
          <Typography>{props.date ?? ''}</Typography>
        </Box>
      </Box>

      <Paper elevation={3} className={'mt-sm'}>
        <Box className={'box-wrapper-content'}>
          <div
            className={isMobile ? 'policy-text-mobile' : ''}
            dangerouslySetInnerHTML={{ __html: props.content ?? '' }}
          ></div>

          {props.isShowAcceptBtn === true ? (
            <Button
              variant={'contained'}
              className={'post-btn'}
              onClick={props?.acceptedHandle}
              disabled={props.disableBtn}
            >
              Accept
            </Button>
          ) : (
            ''
          )}
        </Box>
      </Paper>
    </div>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { DateRangeParams, getListTrainingCoursesByDate } from '../api/platform';

export interface IDataCourses {
  date?: string;
  // cert?: boolean;
  assignment?: boolean;
  token_1: string;
  token_2: string;
  course_id: number;
  campaign_id?: number;
  status: number;
  training_status: number;
  end_date?: string;
  start_date?: string;
  tp_id?: number;
  tp_thumbnail?: string;
  course_name?: string;
  latest_score: string;
  student_id?: number;
  tp_name?: string;
  training_label: string;
  assigned_course_again: number;
  has_certificate: number;
  phish_id?: number | null;
  course_category_name?:string;

}

export interface TrainingCoursesByDateEventsObjectState {
  dataCourses: IDataCourses[];
  isLoading: boolean;
  error?: string;
}

const initialState: TrainingCoursesByDateEventsObjectState = {
  dataCourses: [],
  isLoading: false,
  error: undefined
};

export const trainingCoursesByDateEvents = createAsyncThunk(
  'trainingCoursesByDateEventPlatform',
  async (params: DateRangeParams) => {
    const response = await getListTrainingCoursesByDate(params);
    const data = response.data as IDataCourses[];
    return data
      ? data.map((item) => {
          return {
            student_id: item.student_id ?? undefined,
            training_status: item.training_status ?? 0,
            status: item.status ?? 0,
            start_date: item.start_date ?? '',
            end_date: item.end_date ?? '',
            tp_name: item.tp_name ?? '',
            tp_id: item.tp_id ?? undefined,
            tp_thumbnail: item.tp_thumbnail ?? '',
            campaign_id: item.campaign_id ?? undefined,
            course_id: item.course_id ?? 0,
            latest_score: item.latest_score ?? 'N/A',
            token_1: item.token_1 ?? '',
            token_2: item.token_2 ?? '',
            course_name: item.course_name ?? '',
            training_label: item.training_label ?? '',
            assigned_course_again: item.assigned_course_again ?? 0,
            has_certificate: item.has_certificate ?? 0,
            // cert: item.cert ?? undefined,
            phish_id: item.phish_id ?? undefined,
            course_category_name: item.course_category_name?? ''
          };
        })
      : [];
  }
);

export const trainingCoursesByDateSlice = createSlice({
  name: 'trainingCoursesByDateEvents',
  initialState,
  reducers: {
    setTrainingCoursesByDateEventData: (state, action: PayloadAction<IDataCourses[]>) => {
      state.isLoading = false;
      state.dataCourses = action.payload;
      state.error = undefined;
    },
    removeTrainingCoursesByDateEvent: (state) => {
      state.isLoading = false;
      state.dataCourses = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(trainingCoursesByDateEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      trainingCoursesByDateEvents.fulfilled,
      (state, action: PayloadAction<IDataCourses[]>) => {
        state.isLoading = false;
        state.dataCourses = action.payload;
        state.error = undefined;
      }
    );

    builder.addCase(trainingCoursesByDateEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.dataCourses = [];
      state.error = action.payload as string;
    });
  }
});

export const { setTrainingCoursesByDateEventData,removeTrainingCoursesByDateEvent } = trainingCoursesByDateSlice.actions;

export const trainingCoursesByDateEventsDataSelector = (state: RootState) =>
  state.trainingCoursesByDateEvents;
export default trainingCoursesByDateSlice.reducer;

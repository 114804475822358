import moment from 'moment-timezone';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format, parse } from 'date-fns';
import { FORMAT_DATE } from '../constants/constants';
import { TIME_ZONE } from '../constants/timeZone';
import { getCurrentCellStore } from './util';

export const getCurrentTimeZone = () => {
  let currentTimeZone = 'Etc/UTC';
  const currentCell = getCurrentCellStore();
  if (currentCell) {
    currentTimeZone = TIME_ZONE[currentCell.tz_name as keyof typeof TIME_ZONE];
  }
  return currentTimeZone;
};

export const formatDate = (strDateTime: string, format: string): string => {
  return moment(strDateTime).format(format);
};

export const getDateTime = (dateObj?: Date): string => {
  if (dateObj) {
    return moment(dateObj).format('LT');
  }
  return 'n/a';
};

export const getCurrentIsoDate = (): any => {
  return {
    from: moment().startOf('day').toISOString(),
    to: moment().endOf('day').toISOString()
  };
};

export const getTimeStamp = (date: Date): number => {
  return moment(date).unix();
};

export const getCurrentDate = (): string => {
  return moment().format('DD/MM/yy HH:mm:ss');
};

export const parseDateTImeToUTC = (dateStr: string) => {
  //parse current Datetime to utc time
  const dayZone = zonedTimeToUtc(
    parse(dateStr, FORMAT_DATE.DATE_TIME, new Date()),
    getCurrentTimeZone()
  );
  const dayUTC = utcToZonedTime(
    parse(format(dayZone, FORMAT_DATE.DATE_TIME), FORMAT_DATE.DATE_TIME, new Date()),
    'UTC'
  );
  return dayUTC;
};

// date have format dd/MM/yyyy hh:mm:ss
export const parseDateTimeFormUtcToCurrentZone = (date: any) => {
  const dateDBParseUTC = zonedTimeToUtc(parse(date, FORMAT_DATE.DATE_TIME, new Date()), 'UTC');
  //parse UTC Datetime to current time zone
  const dateCurrentZone = utcToZonedTime(dateDBParseUTC, getCurrentTimeZone());
  return dateCurrentZone;
};

export const convertUtcToTimezone = (dateTime?: string) => {
  if (dateTime) {
    return moment.utc(dateTime).tz(getCurrentTimeZone()).format();
  }
  return '';
};

import React, {useState} from 'react';
import { DashboardProps } from './';
import { TabsHeader } from '../../components/TabsHeader/TabsHeader';
import { PrivatePolicy } from '../../components/PrivatePolicy';
// import { ProfileCard } from '../../components/ProfileCard';
import './Dashboard.css';
// import Grid from '@mui/material/Grid';
import {DASHBOARD_INTRO, TOOLTIP_MESSAGES} from '../../constants/constants';
// import { VerticalCard } from '../../components/VerticalCard';
// import thumbnailImg from '../../assets/images/password-and-passphrases.png';
import {
  // Box,
  // Card,
  // CardContent,
  // Pagination,
  // Stack,
  // Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
// import { WeekCalendar } from '../../components/WeekCalendar';
// import {
//   trainingCoursesByDateEvents,
//   trainingCoursesByDateEventsDataSelector
// } from '../../redux/trainingCoursesByDateSlice';
// import {
//   trainingCoursesQuantityEvents,
//   trainingCoursesEventsDataSelector
// } from '../../redux/trainingCoursesSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// import { HorizontalCard } from '../../components/HorizontalCard';
import { LoadingPage } from '../../components/LoadingPage';
import { addUserAcceptEvents, userAcceptEventsDataSelector } from '../../redux/userAcceptSlice';
import { useNavigate } from "react-router-dom";
import {SnackBar} from "../../components/SnackBar";
// const welcomeData = DASHBOARD_INTRO.WELCOME;
const privacyData = DASHBOARD_INTRO.PRIVACY;

// const outstandingCourse = [
//   {
//     title: 'Password and Passphrases',
//     thumbnail: thumbnailImg
//   }
// ];
// const certificates = [
//   {
//     title: 'Introduction to Information Security',
//     thumbnail: thumbnailImg,
//     max_score: 85
//   },
//   {
//     title: 'Introduction to Information Security',
//     thumbnail: thumbnailImg,
//     max_score: 85
//   },
//   {
//     title: 'Introduction to Information Security',
//     thumbnail: thumbnailImg,
//     max_score: 85
//   }
// ];

export interface DashBoardData {
  label: string;
  component: React.ReactNode;
  hidden?: boolean;
  number?: number;
}

export const Dashboard: React.FC<DashboardProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  // const [hiddenNextPagingButton, setHiddenNextPagingButton] = useState<boolean>(false);
  // const [hiddenPrevPagingButton, setHiddenPrevPagingButton] = useState<boolean>(false);
  const { dataUserAccept, isLoading } = useAppSelector(userAcceptEventsDataSelector);
  // const { dataCourses } = useAppSelector(trainingCoursesByDateEventsDataSelector);
  // const { coursesQuantityData } = useAppSelector(trainingCoursesEventsDataSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

  // useEffect(() => {
  //   if (outstandingCourse.length === 1 || certificates.length === 1) {
  //     setHiddenNextPagingButton(true);
  //     setHiddenPrevPagingButton(true);
  //   }
  // }, []);

  // const showDetailsHandle = (startDate: string, endDate: string) => {
  //   const params = {
  //     start_date: startDate,
  //     end_date: endDate
  //   };
  //   dispatch(trainingCoursesByDateEvents(params));
  // };

  // const changeWeekHandle = (startDate: string, endDate: string) => {
  //   const params = {
  //     start_date: startDate,
  //     end_date: endDate
  //   };
  //   dispatch(trainingCoursesQuantityEvents(params));
  // };

  const acceptHandler = async () => {
    const response = await dispatch(addUserAcceptEvents());
    console.log('addUserAccept-after clicking Accept btn: ', response?.data?.payload?.isAccept);
    if(response && response.payload?.isAccept){
      setMessageSnackbar(TOOLTIP_MESSAGES.PRIVACY_POLICY_ACCEPTED)
      setIsOpenSnackbar(true);
      setTimeout(() => {
        navigate('/courses')
      }, 2000);

    }
  };

  const handleClose = () => {
    setIsOpenSnackbar(false);
  };

  const data = [
    {
      label: 'Home',
      component: (
        <div className={'dashboard-page'}>
          <PrivatePolicy
            title={privacyData.title}
            author={privacyData.author}
            date={privacyData.date}
            content={privacyData.content}
            isShowAcceptBtn={privacyData.isShowAcceptBtn}
            acceptedHandle={() => acceptHandler()}
            disableBtn={Boolean(dataUserAccept.isAccept)}
          />
          {/* <PrivatePolicy
            title={welcomeData.title}
            author={welcomeData.author}
            date={welcomeData.date}
            content={welcomeData.content}
            isShowAcceptBtn={welcomeData.isShowAcceptBtn}
          /> */}
        </div>
      )
    }
  ];

  // if (dataUserAccept.isAccept) {
  //   data.push({
  //     label: 'Learners Dashboard',
  //     component: (
  //       <Box className={'dashboard-page'}>
  //         <Grid container columnSpacing={{ xl: 4, lg: 4, md: 4, sm: 2 }}>
  //           <Grid item>
  //             <ProfileCard />
  //           </Grid>
  //           <Grid item>
  //             <Card>
  //               <CardContent>
  //                 <Typography className={'card-title'}>Outstanding Courses</Typography>
  //                 <Typography className={'card-subtitle'}>Require Immediate Action</Typography>
  //                 <Stack
  //                   direction={{ lg: 'column', md: 'row', xl: 'row', sm: 'row', xs: 'column' }}
  //                   alignItems={{ sm: 'center', xs: 'center', lg: 'center' }}
  //                   spacing={6}
  //                   padding={'15px 20px'}
  //                 >
  //                   {outstandingCourse.map((course, index: number) => {
  //                     return (
  //                       <VerticalCard
  //                         key={index}
  //                         title={course.title}
  //                         image={course.thumbnail}
  //                         isCourse={false}
  //                       />
  //                     );
  //                   })}
  //                 </Stack>
  //                 <Stack direction={'column'} alignItems={'center'}>
  //                   <Pagination
  //                     count={outstandingCourse.length}
  //                     hidePrevButton={hiddenPrevPagingButton}
  //                     hideNextButton={hiddenNextPagingButton}
  //                   />
  //                 </Stack>
  //               </CardContent>
  //             </Card>
  //             <Card sx={{ marginTop: '20px', marginBottom: '15px' }}>
  //               <CardContent>
  //                 <Typography className={'card-title'}>Certificates</Typography>
  //                 <Typography className={'card-subtitle'}>Completed course Results</Typography>
  //                 <Stack
  //                   direction={{ lg: 'column', md: 'row', xl: 'row', sm: 'row', xs: 'column' }}
  //                   alignItems={{ sm: 'center', xs: 'center', lg: 'center' }}
  //                   spacing={6}
  //                   padding={'15px 20px'}
  //                 >
  //                   {certificates.map((course, index: number) => {
  //                     return (
  //                       <VerticalCard
  //                         key={index}
  //                         title={course.title}
  //                         image={course.thumbnail}
  //                         grade={course.max_score}
  //                         isCourse={true}
  //                       />
  //                     );
  //                   })}
  //                 </Stack>
  //                 <Stack direction={'column'} alignItems={'center'}>
  //                   <Pagination
  //                     count={certificates.length}
  //                     hidePrevButton={hiddenPrevPagingButton}
  //                     hideNextButton={hiddenNextPagingButton}
  //                   />
  //                 </Stack>
  //               </CardContent>
  //             </Card>
  //           </Grid>
  //           <Grid item md lg xs>
  //             <WeekCalendar
  //               listCoursePerDay={coursesQuantityData}
  //               showDetailsHandle={(start: string, end: string) => showDetailsHandle(start, end)}
  //               onChangeWeek={(startDate: string, endDate: string) =>
  //                 changeWeekHandle(startDate, endDate)
  //               }
  //             />
  //             <Box component={'div'} className={'box-list'}>
  //               {dataCourses.length > 0
  //                 ? dataCourses.map((course, index: number) => {
  //                     return <HorizontalCard horizontalCardData={course} key={index} />;
  //                   })
  //                 : null}
  //             </Box>
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     )
  //   });
  // }

  return (
    <>
      <TabsHeader data={data} defaultValue={0} isDropdown={isMobile} />
      <LoadingPage open={isLoading} />
      {messageSnackbar && (
        <SnackBar
          open={isOpenSnackbar}
          severity={'success'}
          message={messageSnackbar}
          onClose={() => handleClose()}
        />
      )}
    </>
  );
};

import React from 'react';
import { LeftCardCompletedProps } from './LeftCardCompleted.props';
import './LeftCardCompleted.css';
import { Box, Card, Typography } from '@mui/material';
import './styles/ipad.css';
import './styles/mobile.css';

export const LeftCardCompleted: React.FC<LeftCardCompletedProps> = ({
  ...props
}: LeftCardCompletedProps) => {
  return (
    <>
      <Box className={'completed_course'}>
        <Card className={'completed_course-card'}>
          <img src={props.image} alt="all completed course" className={'card_image'} />
          <Typography className={'card_content'}>{props.contents}</Typography>
        </Card>
      </Box>
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { updateLockLearner } from '../api/platform';

export interface updateLockLearnerEventsObjectState {
  updateLockLearnerData: any;
  isLoading: boolean;
  error?: string;
}

const initialState: updateLockLearnerEventsObjectState = {
  updateLockLearnerData: null,
  isLoading: false,
  error: undefined
};

export const updateLockLearnerEvents = createAsyncThunk(
  'updateLockLearnerEventPlatform',
  async (user_id: string | undefined) => {
    const response = await updateLockLearner(user_id);
    return response;
  }
);

export const updateLockLearnerSlice = createSlice({
  name: 'updateLockLearnerEvents',
  initialState,
  reducers: {
    setUpdateLockLearnerEventData: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.updateLockLearnerData = action.payload;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateLockLearnerEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateLockLearnerEvents.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.updateLockLearnerData = action.payload;
      state.error = undefined;
    });

    builder.addCase(updateLockLearnerEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.updateLockLearnerData = {};
      state.error = action.payload as string;
    });
  }
});

export const { setUpdateLockLearnerEventData } = updateLockLearnerSlice.actions;

export const updateLockLearnerEventsDataSelector = (state: RootState) =>
  state.updateLockLearnerEvents;

export default updateLockLearnerSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface WebsocketState {
  messages: string[];
  isEstablishingConnection: boolean;
  isConnected: boolean;
  error: any;
  unsentMessage: string;
}

const initialState: WebsocketState = {
  messages: [],
  isEstablishingConnection: false,
  isConnected: false,
  error: undefined,
  unsentMessage: ''
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    receiveMessage: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      state.messages.push(action.payload.message);
    },
    submitMessage: (state, action: PayloadAction<string>) => {
      state.messages = [...state.messages, action.payload];
    },
    error: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    disconnect: (state) => {
      state.isConnected = false;
    },
    setUnsentMessage: (state, action: PayloadAction<string>) => {
      state.unsentMessage = action.payload;
    }
  }
});

export const websocketActions = websocketSlice.actions;

export const notificationsDataSelector = (state: RootState) => state.notificationEvents;

export default websocketSlice.reducer;

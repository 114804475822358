import React from 'react';
import { Alert, Button, Snackbar, styled } from '@mui/material';
import { SnackBarProps } from '.';

const MuiButton = styled(Button)`
  color: #ffffff;
  padding: 0;
`;

const MuiAlert = styled(Alert)`
  align-items: center;
  background-color: #ed1c24;
  padding: 6px 21px;
  & .MuiAlert-action {
    padding-top: 0;
    margin-right: 0;
  }
  & .MuiAlert-message {
    font-size: 16px;
    font-weight: 600;
    padding: 0;
  }
  & .MuiAlert-icon {
    font-size: 24px;
  }
`;

const MuiSnackbar = styled(Snackbar)`
  &.MuiSnackbar-anchorOriginBottomRight {
    margin-bottom: 0.5rem;
  }
  &.MuiSnackbar-anchorOriginBottomRight {
    margin-bottom: 0.5rem;
    & .MuiAlert-filledError {
      background-color: #ed1c24;
    },
    & .MuiAlert-filledWarning {
      background-color: #FCAB10;
    },
    & .MuiAlert-filledSuccess {
      background-color: #008545;
    },
  }
`;
export const SnackBar: React.FC<SnackBarProps> = ({ message, severity, open, onClose }) => {
  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={6000}
      sx={{ right: { sm: 20, xs: 20 }, left: { sm: 'auto', xs: 20 }, bottom: { sm: 31, xs: 60 } }}
    >
      <MuiAlert
        variant="filled"
        severity={severity}
        sx={{ width: '100%' }}
        action={
          <MuiButton sx={{ fontSize: '20px' }} variant="text" onClick={onClose}>
            CLOSE
          </MuiButton>
        }
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

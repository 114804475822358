import React, { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { OnBoardingOverviewProps } from './OnBoardingOverview.props';
import './OnBoardingOverview.css';
import {ITotalDataFilter, SearchMenuBar} from '../../components/SearchMenuBar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  learnersDataSelector,
  OnBoardingLearner,
  onBoardingResultEvents, OnBoardingParams
} from '../../redux/learnersSlice';
import { DataTables } from '../../components/DataTables';
import { Stack, styled, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { UserAvatarLetters } from '../../components/UserAvatarLetters';
import { CustomChip } from '../../components/CustomChip';
import { CustomPagination } from '../../components/DataTables/components/CustomPagination';
import { Public } from '@mui/icons-material';
import { MobileDataCell, MobileDataTable } from '../../components/MobileDataTable';
import './styles/mobile.css';
import { LoadingPage } from '../../components/LoadingPage';

interface OnBoardingRow extends OnBoardingLearner {
  id: number;
}

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'asc' }];

export const OnBoardingOverview: React.FC<OnBoardingOverviewProps> = ({setOnBoardingNumber}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
  const [onBoardingRows, setOnBoardingRows] = useState<OnBoardingRow[]>([]);
  const dispatch = useAppDispatch();
  const [totalPage, setTotalPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { onBoardingData, isStatusLoading, isOnBoardingLoading } = useAppSelector(learnersDataSelector);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'));
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const defaultSelectedItems = {
    'Privacy Policy': []
  };
  const defaultParams :OnBoardingParams  ={
    per_pages: 10,
    keyword: '',
    privacy_policy: ['Accepted', 'Not Accepted'],
    page: 1,
    sort_column:"first_name",
    sort_type:'asc',
  }
  const [mainParams, setMainParams] = useState<any>(defaultParams);

  const getOnboardingOverviews = async (params: OnBoardingParams, isFirstTimes?:boolean) => {
    setMainParams(params);
    const result = await dispatch(
      onBoardingResultEvents(params)
    );
    if(!isFirstTimes && result && result.payload) {
      setOnBoardingNumber && setOnBoardingNumber(result.payload.total_items)
    }
  };

  useEffect(() => {
    dispatch(onBoardingResultEvents(defaultParams));
  }, [dispatch]);

  useEffect(() => {
    const rows: OnBoardingRow[] = onBoardingData.list.map((learner, index) => ({
      ...learner,
      id: index
    }));
    setOnBoardingRows(rows);
    setTotalPage(onBoardingData['total_page']);
    setTotalRows(onBoardingData['total_items']);
    setStartPage(onBoardingData['start_item']);
    setEndPage(onBoardingData['end_item']);
  }, [onBoardingData]);

  const totalData: ITotalDataFilter[] = [
    {
      key: 'Privacy Policy',
      label: 'Training Disclosure',
      data: [
        { id: 1, content: 'Accepted' },
        { id: 2, content: 'Not Accepted' }
      ]
    }
  ];

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const model = newSortModel.length > 0 ? newSortModel : defaultSortModel;
    const params = {
      ...mainParams,
      sort_type: model[0].sort,
      sort_column: model[0].field
    }
    getOnboardingOverviews(params);
  }
  const handleClearFilter = (type: any) => {
    setSelectedItems((prev: any) => {
      return {
        ...prev,
        [type]: []
      };
    });
    const selectedItem = {
      ...selectedItems,
      [type]: []
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if(type === 'Privacy Policy'){
        selectedItem[type] = totalData[0].data.map(item => item.content)
      }
    }
    const params ={
      ...mainParams,
      privacy_policy: selectedItem['Privacy Policy'],
      page: 1
    }
    setCurrentPage(1);
    getOnboardingOverviews(params);
  };

  const resetFilters = () => {
    setSelectedItems(defaultSelectedItems);
    setFilterSearchKeyword('');
    const params ={
      ...mainParams,
      keyword:'',
      page: 1,
      privacy_policy: totalData[0].data.map(item => item.content)
    }
    setCurrentPage(1);
    getOnboardingOverviews(params);

  };
  const handlePrivacyPolicyFilter = (item: any, type: any) => {
    setSelectedItems((prev: any) => {
      return ({
        ...prev,
        [type]: item
      });
    });
    const selectedItem = {
      ...selectedItems,
      [type]: item
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if(type === 'Privacy Policy'){
        selectedItem[type] = totalData[0].data.map(item => item.content)
      }
    }
    const params ={
      ...mainParams,
      page: 1,
      privacy_policy: selectedItem['Privacy Policy']
    }
    setCurrentPage(1);
    getOnboardingOverviews(params);
  };
  const onSearchInboxByKeywords = (event: any) => {
    if (event.keyCode === 13) {
      const params ={
        ...mainParams,
        keyword: event.target.value,
        page: 1
      }
      setCurrentPage(1);
      getOnboardingOverviews(params);
    }
  };

  const onRowsPerPageChange=(perPage:number)=>{
    const params = {
      ...mainParams,
      per_pages: perPage,
      page: 1
    }
    getOnboardingOverviews(params, true);
  }
  const onChangePage=(currentPage:number)=>{
    const params = {
      ...mainParams,
      page:currentPage,
    }
    getOnboardingOverviews(params, true);
  }

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Learners',
      headerClassName: 'data-tables-header-cell-start',
      flex: isDesktop ? 3 : 4,
      renderCell: (params: GridRenderCellParams) => {
        const firstName = params.row.first_name ? params.row.first_name.trim() : '';
        const lastName = params.row.last_name ? params.row.last_name.trim() : '';
        const learnerName = firstName + " " + lastName;
        return (
          <Stack direction='row' alignItems='center' spacing={1.875} className={'on-boarding-cell'}>
            <UserAvatarLetters name={learnerName} classNames={'on-boarding-user-name'} />
            <Stack>
              <CellText>{learnerName}</CellText>
              <CellText color='secondary.main' fontWeight={700} style={{
                lineBreak: 'anywhere'
              }}>
                <a className={'on-boarding-cell-email'} href={`mailto:${params.row.email}`}>{params.row.email}</a>
              </CellText>
            </Stack>
          </Stack>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Training Disclosure',
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <CustomChip
            label={params.value ? 'Accepted' : 'Not Accepted'}
            sx={{
              backgroundColor: params.value ? '#EAF7C6' : '#FFCACF',
              border: `1px solid ${params.value ? '#A2BC30' : '#F74342'}`
            }}
            className='custom-chip'
          />
        );
      }
    }
  ];

  const mobileCells: MobileDataCell<OnBoardingLearner>[] = [
    (params: OnBoardingLearner) => {
      const firstName = params.first_name ? params.first_name.trim() : '';
      const lastName = params.last_name ? params.last_name.trim() : '';
      const learnerName = firstName + " " + lastName;
      return (
        <Stack direction='row' alignItems='center' spacing={1.875} padding='10px 0'>
          <UserAvatarLetters name={learnerName} />
          <Stack spacing={1}>
            <CellText>{learnerName}</CellText>
            <CellText color='secondary.main' fontWeight={700}>
              {learnerName}
            </CellText>
          </Stack>
        </Stack>
      );
    },
    (params: OnBoardingLearner) => (
      <Stack className="chip-cell" direction='row' justifyContent='space-between'>
        <Typography fontSize={12} fontWeight={600}>
          Privacy Policy
        </Typography>
        <CustomChip
          label={params.status ? 'Accepted' : 'Not Accepted'}
          sx={{
            backgroundColor: params.status ? '#EAF7C6' : '#FFCACF',
            border: `1px solid ${params.status ? '#A2BC30' : '#F74342'}`
          }}
          className='custom-chip'
        />
      </Stack>
    )
  ];

  return (
    <Stack
      spacing={isMobile ? 5 : 0}
      className='data-container'
      id="onboard-data-container"
    >
      <SearchMenuBar
        selectedItems={selectedItems}
        resetFilter={resetFilters}
        handleEnterSearch={onSearchInboxByKeywords}
        onFilter={handlePrivacyPolicyFilter}
        searchInputValue={filterSearchKeyword}
        setSearchInputValue={setFilterSearchKeyword}
        onClearFilter={handleClearFilter}
        totalDataFilter={totalData}
        mobileDrawerIcon={<Public />}
        mobileDrawerTitle='On-Boarding'
      />
      {isMobile ? (
        <MobileDataTable<OnBoardingLearner> data={onBoardingRows} cells={mobileCells} />
      ) : (
        <>
          <DataTables
            tblRows={onBoardingRows}
            tblColumns={columns}
            totalRows={totalRows}
            sortingMode={'server'}
            sortModelChangeHandler={handleSortModelChange}
            customPaging={
              <CustomPagination
                rowsPerPageOptions={[5, 10, 20]}
                currentPage={currentPage}
                totalPage={totalPage}
                perPage={perPage}
                startPage={startPage}
                endPage={endPage}
                totalRows={totalRows}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                onChangePage = {onChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            }
          />
          <LoadingPage open={isOnBoardingLoading || isStatusLoading}/>
        </>
      )}
    </Stack>
  );
};

const CellText = styled((props: TypographyProps) => (
  <Typography fontWeight={500} fontSize={14} {...props} />
))((props: TypographyProps) => ({
  sx: { fontWeight: props.fontWeight, fontSize: props.fontSize }
}));

import React, { useEffect, useState } from "react";
import {Navigate, Outlet} from "react-router-dom";
import { getUserAcceptEvents, userAcceptEventsDataSelector } from '../redux/userAcceptSlice';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
export const AuthRoute: React.FC<{ isLogged: boolean | undefined }> = ({ isLogged }) => {
  const dispatch = useAppDispatch();
  const { dataUserAccept } = useAppSelector(userAcceptEventsDataSelector);
  const [ui, setUI] =useState(<></>)
  const getUserAcceptInformation = async () => {
    await dispatch(getUserAcceptEvents());
  };
  useEffect(() => {
    if(isLogged){
      getUserAcceptInformation();
    }
  }, [isLogged]);

  useEffect(() => {
    if (isLogged && location.pathname.includes('/login')) {
      if(dataUserAccept && dataUserAccept.isAccept){
       setUI(<Navigate to="/courses" replace />);
      } else{
        setUI(<Navigate to="/dashboard" replace />);
      }
    } else{
      setUI(<Outlet />);
    }
  }, [dataUserAccept]);

  return ui;
};
import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    black_darker: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    black_darker?: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    black: true;
  }
}

const LightTheme: ThemeOptions = {
  palette: {
    black: {
      light: '#808888',
      main: '#011111',
      dark: '#4D5858'
    },
    black_darker: {
      light: '#808888',
      main: '#011111',
      dark: '#4D5858'
    },
    secondary: {
      main: '#467599'
    },
    error: {
      main: '#B00020'
    },
    primary: {
      main: '#193051',
      dark: '#193051'
    }
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: 14
    },
    fontSize: 14,
    fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif'].join(',')
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: 16,
          '& .MuiChip-labelSmall': {
            padding: 4,
            fontSize: 12
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            fontWeight: 600
          },
          '&.MuiButton-outlined': {
            border: '1px solid #E0E0E0',
            fontSize: '14px'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
          fontSize: 12,
          lineHeight: 1.5
        }
      }
    },
    MuiList: {
      // styleOverrides: {
      //   root: {
      //     borderRadius: 4,
      //     boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      //     border: '1px solid #CCCFCF'
      //   }
      // }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          paddingRight: 30,
          '&.Mui-selected': {
            // backgroundColor: '#DFF4ED'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 640,
      tablet: 1180,
      laptop: 1366,
      desktop: 1920
    }
  }
};

export default LightTheme;

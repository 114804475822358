import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface Notification {
  title: string;
  body: string;
}

export interface NotificationsSliceState {
  isLoading: false;
  notifications: Notification[];
  error: object;
  unread: number;
  firebaseToken: string;
}

const initialState: NotificationsSliceState = {
  isLoading: false,
  notifications: [],
  error: {},
  unread: 0,
  firebaseToken: ''
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state: any, action: PayloadAction<Notification | undefined>) => {
      state.notifications = [...state.notifications, action.payload];
      state.error = undefined;
      state.unread += 1;
    },
    setUnread: (state: any, action: PayloadAction<number | undefined>) => {
      state.unread = action.payload;
    },
    setFirebaseToken: (state, action: PayloadAction<string | undefined>) => {
      state.firebaseToken = action.payload || '';
    }
  }
});

export const notificationActions = notificationsSlice.actions;

export const notificationsDataSelector = (state: RootState) => state.notificationEvents;

export default notificationsSlice.reducer;

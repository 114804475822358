import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getCertificate } from '../api/platform';

export interface ICertificateData {
  file_data?: string;
  file_name?: string;
}
export interface GetCertificateEventsObjectState {
  certificateData: ICertificateData;
  isLoading: boolean;
  error?: object;
}

const initialState: GetCertificateEventsObjectState = {
  certificateData: {
    file_name: 'certificate.pdf',
    file_data: ''
  },
  isLoading: false,
  error: {}
};

export const getCertificateEvents = createAsyncThunk(
  'getCertificateEventPlatform',
  async (phish_id: any) => {
    const response = await getCertificate(phish_id);
    return {
      file_name: response['file_name'],
      file_data: response['file_data']
    };
  }
);

export const getCertificateSlice = createSlice({
  name: 'getCertificateEvents',
  initialState,
  reducers: {
    setGetCertificateEventData: (state, action: PayloadAction<ICertificateData>) => {
      state.isLoading = false;
      state.certificateData = action.payload;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificateEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCertificateEvents.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.certificateData = action.payload;
      state.error = undefined;
    });

    builder.addCase(getCertificateEvents.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.certificateData = {};
      state.error = action.payload as string;
    });
  }
});

export const { setGetCertificateEventData } = getCertificateSlice.actions;

export const getCertificateEventsDataSelector = (state: RootState) => state.getCertificateEvents;
export default getCertificateSlice.reducer;

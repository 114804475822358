import {
  Box,
  Button,
  Card,
  CardMedia, Tooltip,
  Typography, useMediaQuery, useTheme
} from '@mui/material';
import PassedIcon from '../../assets/images/passed-icon.svg';
import OutstandingIcon from '../../assets/images/outstanding-icon.svg';
import AssignedIcon from '../../assets/images/assigned-icon.svg';
import EventIcon from '@mui/icons-material/Event';
import './HorizontalCard.css';
import React from 'react';
import { HorizontalCardProps } from './HorizontalCard.props';
import moment from 'moment';
import { handleStartCourse, parseImgURL } from '../../utils/util';
import './styles/ipad.css';
import './styles/mobile.css';
import { convertUtcToTimezone } from '../../utils/dateTimeHelper';
import { FORMAT_DATE } from '../../constants/constants';

export const HorizontalCard: React.FC<HorizontalCardProps> = (props) => {
  const { horizontalCardData } = props;
  const utcByTimezone = convertUtcToTimezone(horizontalCardData?.end_date);
  const theme =useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  const showTagIconIncludedTooltip = (tagIcon?: string) => {
    const tags = {
      Completed: {
        title: 'Passed Course',
        icon: () => {
          return isMobile ?  <img src={PassedIcon} className={'completed'} width={'38px'} height={'38px'} /> :   <img src={PassedIcon} className={'completed'} />;
        }
      },

      Outstanding: {
        title: 'Outstanding Course',
        icon: () => {
          return isMobile ?  <img src={OutstandingIcon} className={'outstanding'} width={'50px'} height={'50px'}/>:  <img src={OutstandingIcon} className={'outstanding'} />;
        }
      },
      Assigned: {
        title: 'Assigned Course',
        icon: () => {
          return isMobile ?  <img src={AssignedIcon} className={'assigned'} width={'50px'} height={'50px'} /> : <img src={AssignedIcon} className={'assigned'} /> ;
        }
      }
    };

    if (tagIcon) {
      return (
          <Tooltip
              PopperProps={{
                     sx:{marginBottom:'-59px !important'}
                   }}
              placement={'top-start'}
              title={`${tags[tagIcon as keyof typeof tags].title}`}

          >
            {tags[tagIcon as keyof typeof tags].icon()}
          </Tooltip>
      );
    }
  };

  const showButtonStartCourse= (latestScore:string) => {
    return (<div className={'btn-wrapper'}>
      {(latestScore === 'N/A' || Number(latestScore) < 80) ? (
          <Button
              data-testid="btn-start-course"
              className={'course-card-btn'}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleStartCourse(horizontalCardData?.token_1, horizontalCardData?.token_2);
              }}
          >
            Start Course
          </Button>
      ) : (
          <Typography className={'grade-btn'}>
            Grade | {latestScore}%
          </Typography>
      )}
    </div>)
  }
  return (
    <>
      <Card className={'course-card-style course-card-action ' + `${props?.className}`} onClick={props.onSelected}>
          <Box  className={'course-wrapper'} onClick={props.clickToDetail}>
            <div className={'course-wrapper-elements'}>
              <div className={'course-header'}>
                <div className={'course-header-wrapper-img'}>
                  <CardMedia
                    component={'img'}
                    alt={horizontalCardData?.tp_thumbnail}
                    className={'course-thumbnail'}
                    image={`${parseImgURL(
                      horizontalCardData?.tp_thumbnail,
                      horizontalCardData?.tp_id
                    )}`}
                  />
                </div>

                <div className={'course-contain'}>
                  <Typography className={'title text-truncate multiline-ellipsis'}>
                    {horizontalCardData?.course_name ?? ''}
                  </Typography>
                  <Typography className={'sub-title text-truncate multiline-ellipsis'}>
                    {horizontalCardData?.course_category_name ?? ''}
                  </Typography>

                </div>
                {
                  isMobile ? showTagIconIncludedTooltip (horizontalCardData.training_label) : null
                }
              </div>
              <div className="course-infos-wrapper">
                <Box className={'course-infos'} component={'div'}>
                  {showButtonStartCourse(horizontalCardData.latest_score)}
                  <div className={'course-info'}>
                    <Box className={'box-due-date'}>
                      <Tooltip title={'Due Date'} placement={'top'}>
                        <EventIcon className={'calendar-icon'} />
                      </Tooltip>
                      <Typography className={'date-month'}>
                        {moment(utcByTimezone).format(FORMAT_DATE.DATE_TIME_ROUND_TRIP) ?? ''}
                      </Typography>
                    </Box>
                  </div>

                </Box>
              </div>
            </div>
            { isMobile? null : showTagIconIncludedTooltip(horizontalCardData.training_label)}
          </Box>
      </Card>
    </>
  );
};

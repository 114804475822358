import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {ITotalDataFilter, SearchMenuBar} from '../../components/SearchMenuBar';
import { CourseResultsOverviewProps } from './CourseResultsOverview.props';
import { useAppSelector } from '../../redux/hooks';
import {
  CourseResult,
  TrainingStatus,
  getCourseResultsTabEvent,
  getCoursesFilterEvent,
  learnersDataSelector,
} from '../../redux/learnersSlice';
import { DataTables } from '../../components/DataTables';
import {GridColDef, GridRenderCellParams, GridSortModel} from '@mui/x-data-grid';
import {
  Stack,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { UserAvatarLetters } from '../../components/UserAvatarLetters';
import { CustomChip } from '../../components/CustomChip';
import { CustomPagination } from '../../components/DataTables/components/CustomPagination';
import { AssignmentTurnedIn } from '@mui/icons-material';
import { BasicCell, MobileDataCell, MobileDataTable } from '../../components/MobileDataTable';
import './CourseResultsOverview.css';
import './styles/mobile.css';
import { LoadingPage } from '../../components/LoadingPage';

interface CourseResultsRow extends CourseResult {
  id: number;
}
const defaultSelectedItems = {
  Courses: [],
  'Training Status': []
};
const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'asc' }];

interface ExpandableMobileChipCellProps {
  params: CourseResult;
}

export const CourseResultsOverview: React.FC<CourseResultsOverviewProps> = ({setCourseResultNumber}) => {
  const [selectedItems, setSelectedItems] = useState<any>(defaultSelectedItems);
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
  const [rows, setRows] = useState<CourseResultsRow[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch<any>();

  const { isCourseLoading, isStatusLoading, isLoadingCoursesFilter, courses=[], courseResultsData } = useAppSelector(learnersDataSelector);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const isLaptop = useMediaQuery(theme.breakpoints.between('tablet', 'laptop'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const totalData: ITotalDataFilter[] = [
    {
      key: 'Courses',
      data: courses,
      isDisable: courses.length < 1
    },
    {
      key: 'Training Status',
      data: [
        { id: 1, content: 'Completed' },
        { id: 2, content: 'Failed' },
        { id: 3, content: 'Incomplete' },
        { id: 4, content: 'Not Started' }
      ]
    }
  ];

  const TRAINING_STATUS = ['Completed', 'Failed', 'Incomplete', 'Not Started']
  const defaultParams ={
    per_pages: 10,
    page: 1,
    keyword: filterSearchKeyword,
    course: [],
    training_status: TRAINING_STATUS,
    sort_type: 'asc',
    sort_column: 'first_name',

  }
  const [mainParams, setMainParams] = useState<any>(defaultParams);

  const getCourseResults = async (params:any, isFirstTimes?:boolean) => {
    setMainParams(params);
    const result = await dispatch(
      getCourseResultsTabEvent(params)
    );

    if(!isFirstTimes && result && result.payload){
      setCourseResultNumber && setCourseResultNumber(result.payload.total_items)
    }
  };
  const getCourseFilter = async (isFirstTimes?: boolean)=>{
    const result = await dispatch(getCoursesFilterEvent());
    if(result && result.payload.length > 0){
      const params = {
        ...defaultParams,
        course: result.payload
      }
      await getCourseResults(params, isFirstTimes);
    }else {
      await getCourseResults(defaultParams, isFirstTimes);
    }
  }

  useEffect(() => {
    getCourseFilter(true)
  }, [dispatch]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const model = newSortModel.length > 0 ? newSortModel : defaultSortModel;
    const params = {
      ...mainParams,
      sort_type: model[0].sort,
      sort_column: model[0].field
    }
    getCourseResults(params);
  }
  const handleClearFilter = (type: any) => {
    setSelectedItems((prev: any) => {
      return {
        ...prev,
        [type]: []
      };
    });
    const selectedItem = {
      ...selectedItems,
      [type]: []
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if(type === 'Courses'){
        selectedItem[type] = courses.map(item => item.content)
      }
      if(type === 'Training Status'){
        selectedItem[type] = TRAINING_STATUS
      }
    }
    const params ={
      ...mainParams,
      course: (type === 'Courses') ? selectedItem['Courses'] : mainParams.course,
      training_status: (type === 'Training Status') ? selectedItem['Training Status'] : mainParams.training_status,
      page: 1
    }
    setCurrentPage(1);
    getCourseResults(params);
  };

  const resetFilters = () => {
    setSelectedItems(defaultSelectedItems);
    setFilterSearchKeyword('');
    const params ={
      ...mainParams,
      keyword:'',
      page: 1,
      course: courses.map(item => item.content),
      training_status: TRAINING_STATUS
    }
    setCurrentPage(1);
    getCourseResults(params);

  };
  const handleFilter = (item: any, type: any) => {
    setSelectedItems((prev: any) => {
      return ({
        ...prev,
        [type]: item
      });
    });
    const selectedItem = {
      ...selectedItems,
      [type]: item
    }
    if(selectedItem[type] && selectedItem[type].length ===0) {
      if (type === 'Courses') {
        selectedItem[type] = courses.map(item => item.content);
      }
      if (type === 'Training Status') {
        selectedItem[type] = TRAINING_STATUS;
      }

    }
    const params ={
      ...mainParams,
      page: 1,
      course: (type === 'Courses') ? selectedItem['Courses'] : mainParams.course,
      training_status: (type === 'Training Status') ? selectedItem['Training Status'] : mainParams.training_status
    }
    setCurrentPage(1);
    getCourseResults(params);
  };
  const onSearchInboxByKeywords = (event: any) => {
    if (event.keyCode === 13) {
      const params ={
        ...mainParams,
        keyword: event.target.value,
        page: 1
      }
      setCurrentPage(1);
      getCourseResults(params);
    }
  };
  const onRowsPerPageChange=(perPage:number)=>{
    const params = {
      ...mainParams,
      per_pages: perPage,
      page: 1
    }
    getCourseResults(params, true);
  }
  const onChangePage=(currentPage:number)=>{
    const params = {
      ...mainParams,
      page:currentPage,
    }
    getCourseResults(params, true);
  }

  useEffect(() => {
    const data: CourseResultsRow[] = courseResultsData.list.map((result, index) => ({
      ...result,
      id: index
    }));

    setRows(data);
    setTotalPage(courseResultsData['total_page']);
    setTotalRows(courseResultsData['total_items']);
    setStartPage(courseResultsData['start_item']);
    setEndPage(courseResultsData['end_item']);
  }, [courseResultsData]);

  const getCourseChipStyle = (status: TrainingStatus) => {
    const getStyle = (primaryColor: string, secondaryColor: string) => ({
      backgroundColor: primaryColor,
      border: `1px solid ${secondaryColor}`,
      '.MuiSvgIcon-root': {
        color: secondaryColor
      },
      '.MuiChip-deleteIcon:hover': {
        color: secondaryColor
      },
      height: 'auto',
      '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal'
      }
    });

    switch (status) {
      case 'Completed':
        return getStyle('#EAF7C6', '#A2BC30');
      case 'Failed':
        return getStyle('#FFCACF', '#F74342');
      case 'Incomplete':
        return getStyle('#FFECB3', '#FFB300');
      case 'Not Started':
        return getStyle('#F7F7F7', '#677070');

      default:
        return null;
    }
  };

  const getChipRightIcon = (status: TrainingStatus) => {
    switch (status) {
      case 'Completed':
        return <CheckIcon />;
      case 'Failed':
        return <CloseIcon />;
      case 'Incomplete':
        return <ErrorOutlineIcon />;
      case 'Not Started':
        return <RemoveCircleOutlineIcon />;

      default:
        return undefined;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'Learners',
      flex: isLaptop ? 1 : 2,
      headerClassName: 'course-result-header-start',
      renderCell: (params: GridRenderCellParams) => {
        const firstName = params.row.first_name ? params.row.first_name.trim() : '';
        const lastName = params.row.last_name ? params.row.last_name.trim() : '';
        const learnerName = firstName + ' ' + lastName;
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.875}
            className={'course-result-cell-start'}
          >
            <UserAvatarLetters name={learnerName} />
            <Stack>
              <CellText>{learnerName}</CellText>
              <CellText
                color="secondary.main"
                fontWeight={700}
                style={{
                  lineBreak: 'anywhere'
                }}
              >
                <a className={'course-result-cell-email'} href={`mailto:${params.row.email}`}>
                  {params.row.email}
                </a>
              </CellText>
            </Stack>
          </Stack>
        );
      }
    },
    {
      field: 'course_name',
      sortable: true,
      headerName: 'Course/ Training Status',
      flex: isDesktop ? 2 : isLaptop ? 1 : 2,
      renderCell: (params: any) => {
        return (
          <Tooltip
            title={params.row.training_status === "Incomplete" ? "Incompleted" : params.row.training_status}
            placement={'top-start'}
            PopperProps={{
              sx: { marginBottom: '-15px !important' }
            }}
            followCursor={true}
          >
            <div>
              <CustomChip
                label={params.row.course_name}
                sx={getCourseChipStyle(params.row.training_status)}
                deleteIcon={getChipRightIcon(params.row.training_status)}
                onDelete={() => void 0}
                className="custom-chip has-icon"
              />
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'attempts',
      headerName: 'Attempts',
      flex: isLaptop ? 0.5 : 1,
      align:'right',
      sortable: true,
      renderCell: (params: any) => {
        return <CellText>{params.row.attempts}</CellText>;
      }
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 0,
      align:'right',
      sortable:true,
      renderCell: (params: any) => {
        return (
          <CellText>
            {Number.isFinite(params.row.grade) ? `${params.row.grade}%`:'N/A'}
          </CellText>
        );
      }
    }
  ];

  const ExpandableMobileChipCell: React.FC<ExpandableMobileChipCellProps> = ({ params }) => {
    const [chipExpanded, setChipExpanded] = useState(false);

    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={chipExpanded ? 'center' : 'space-between'}
        className='expandable-mobile-chip-cell'
      >
        {!chipExpanded && <Typography fontSize={12} fontWeight={600}>
          Courses
        </Typography>}
        <CustomChip
          label={params.course_name}
          sx={getCourseChipStyle(params.training_status)}
          deleteIcon={getChipRightIcon(params.training_status)}
          onDelete={() => setChipExpanded(!chipExpanded)}
          onClick={() => setChipExpanded(!chipExpanded)}
          className={`custom-chip has-icon ${chipExpanded ? 'expanded-chip' : ''}`}
        />
      </Stack>
    );
  };

  const mobileCells: MobileDataCell<CourseResult>[] = [
    (params: CourseResult) => {
      const firstName = params.first_name ? params.first_name.trim() : '';
      const lastName = params.last_name ? params.last_name.trim() : '';
      const learnerName = firstName + ' ' + lastName;
      return (
        <Stack direction="row" alignItems="center" spacing={1.875}>
          <UserAvatarLetters name={learnerName}/>
          <Stack>
            <CellText>{learnerName}</CellText>
            <CellText color="secondary.main" fontWeight={700}>
              {params.email}
            </CellText>
          </Stack>
        </Stack>
      );
    },
    (params: CourseResult) => <ExpandableMobileChipCell params={params}/>,
    (params: CourseResult) => <BasicCell cellName='Attempts' cellContent={params.attempts.toString()} />,
    (params: CourseResult) => <BasicCell cellName='Grade' cellContent={params.grade ? `${params.grade}%` : 'N/A'}/>,
  ];

  return (
    <Stack
      spacing={isMobile ? 5 : 0}
      id="course-results-data-container"
    >
      <SearchMenuBar
        selectedItems={selectedItems}
        resetFilter={resetFilters}
        handleEnterSearch={onSearchInboxByKeywords}
        onFilter={handleFilter}
        searchInputValue={filterSearchKeyword}
        setSearchInputValue={setFilterSearchKeyword}
        onClearFilter={handleClearFilter}
        totalDataFilter={totalData}
        mobileDrawerIcon={<AssignmentTurnedIn />}
        mobileDrawerTitle='Course Results'
      />
      {isMobile ? (
        <MobileDataTable data={rows} cells={mobileCells} />
      ) : (
        <>
          <DataTables
            tblRows={rows}
            tblColumns={columns}
            totalRows={totalRows}
            sortModelChangeHandler={handleSortModelChange}
            customPaging={
              <CustomPagination
                rowsPerPageOptions={[5, 10, 20]}
                currentPage={currentPage}
                totalPage={totalPage}
                perPage={perPage}
                startPage={startPage}
                endPage={endPage}
                totalRows={totalRows}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                onChangePage = {onChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            }
          />
          <LoadingPage open={isCourseLoading || isStatusLoading || isLoadingCoursesFilter} />
        </>
      )}
    </Stack>
  );
};

const CellText = styled((props: TypographyProps) => (
  <Typography fontWeight={500} fontSize={14} {...props} />
))((props: TypographyProps) => ({
  sx: { fontWeight: props.fontWeight, fontSize: props.fontSize }
}));

import { Box, Button, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './CardDetail.css';
import { contentProps } from './CardDetail.props';

import { WeekCalendar } from '../../components/WeekCalendar';
import {trainingCoursesEventsDataSelector, trainingCoursesQuantityEvents} from '../../redux/trainingCoursesSlice';
import { useAppSelector } from '../../redux/hooks';
import { HorizontalCard } from '../../components/HorizontalCard';
import { LeftCardCompleted } from '../../components/LeftCardCompleted';
import {
  IDataCourses,
  trainingCoursesByDateEvents,
  trainingCoursesByDateEventsDataSelector
} from '../../redux/trainingCoursesByDateSlice';
import ChangeWorkSpace from '../../hoc/changeWorkSpace';
import { LeftCardDetail } from '../../components/LeftCardDetail';
import CertificateArtwork from '../../assets/images/a_certificate_artwork.svg';
import { LoadingPage } from '../../components/LoadingPage';
import {
  trainingCourseDetailEvents,
  trainingCourseDetailEventsDataSelector
} from '../../redux/trainingCourseDetailSlice';
import CloseIcon from '@mui/icons-material/Close';
import './styles/mobile.css';
import './styles/ipad.css';
import moment from 'moment-timezone';
import { FORMAT_MOMENT } from '../../constants/constants';
import { convertUtcToTimezone, getCurrentTimeZone } from '../../utils/dateTimeHelper';
import { ETrainingLabel } from '../../constants/enums';
import { DateRangeParams } from '../../api/platform';
import {useDispatch} from "react-redux";
import {workSpaceEventsDataSelector} from "../../redux/workSpaceSlice";
import { trainingCoursesQuantityAllEvents } from '../../redux/trainingCourseQuantityAllSlice';

const currentTimeZone = getCurrentTimeZone();
moment.tz.setDefault(currentTimeZone);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '15px 23px 30px 23px',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  height: '94.5%'
}));

const CardDetailComponent: React.FC<contentProps> = () => {
const currentTimeZone = getCurrentTimeZone();
moment.tz.setDefault(currentTimeZone);

  const paramsOneMonth = (currentDateParam?:moment.Moment) => {
    // recall func get timezone if it have any change
    const currentTimeZone = getCurrentTimeZone();
    moment.tz.setDefault(currentTimeZone);
    const currentDate = currentDateParam ? moment(currentDateParam).tz(currentTimeZone) : moment().tz(currentTimeZone)
    const startDate = moment.utc(moment(currentDate).startOf('month')).format(FORMAT_MOMENT.DATE_TIME_DASH);
    const endDate = moment.utc(moment(currentDate).endOf('month')).format(FORMAT_MOMENT.DATE_TIME_DASH);
    return {
      start_date: startDate,
      end_date: endDate
    };
  };

  const dispatch = useDispatch<any>();
  const [courseActive, setCourseActive] = useState(-1);
  const [openPopup, setOpenPopup] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { courseDetails } = useAppSelector(trainingCourseDetailEventsDataSelector);
  const courseLoading = useAppSelector(trainingCourseDetailEventsDataSelector).isLoading;
  const [courseDetailData, setCourseDetailData] = useState<any>(courseDetails);
  const [currentCourseQuantityRange, setCurrentCourseQuantityRange] = useState<DateRangeParams>({
    start_date: '',
    end_date: ''
  });
  const [currentMonth, setCurrentMonth] = useState<DateRangeParams>(paramsOneMonth());
  const { dataCourses, isLoading } = useAppSelector(trainingCoursesByDateEventsDataSelector);
  const { workSpaceData =[] } = useAppSelector(workSpaceEventsDataSelector);
  const {  isLoadingCoursesQuantity } = useAppSelector(trainingCoursesEventsDataSelector);

  const [shouldStop, setShouldStop] = useState(false);
  const moment2 = moment().subtract(1, 'year')
  const [dateFlag, setDateFlag] = useState(moment().tz(currentTimeZone));
  const [imDate, setImDate] = useState(moment().format());

  useEffect(() => {
    setCourseDetailData(courseDetails);
  }, [courseDetails]);

  const courseDetailHandler = (courseData: IDataCourses) => {
    const data = {
      student_id: courseData.student_id,
      campaign_id: courseData.campaign_id
    };
    dispatch(trainingCoursesQuantityEvents(currentCourseQuantityRange));
    dispatch(trainingCoursesByDateEvents(currentMonth));
    dispatch(trainingCourseDetailEvents(data));
    setOpenPopup(isMobile);
  };

  const showDetailsHandle = (startDate: string, endDate: string) => {
    const params = {
      start_date: startDate,
      end_date: endDate
    };
    dispatch(trainingCoursesQuantityEvents(currentCourseQuantityRange));
    dispatch(trainingCoursesByDateEvents(params)).then(() => {
      setCurrentMonth(params);
    });
  };

  const changeWeekHandle = (startDate: string, endDate: string) => {
    const params = {
      start_date: startDate,
      end_date: endDate
    };
    setCurrentCourseQuantityRange(params);
    dispatch(trainingCoursesQuantityEvents(params));
    dispatch(trainingCoursesByDateEvents(currentMonth));
  };

  const closeDetailHandler = () => {
    setOpenPopup(false);
    setCourseDetailData({});
  };

  useEffect( () => {
    if (workSpaceData.length > 0) {
      setCurrentCourseQuantityRange(paramsSixMonth())
      dispatch(trainingCoursesQuantityEvents(paramsSixMonth())).then(()=>{
          findMonthDataWithoutCompleted()
      });
    }
  }, [dispatch]);

  const durationToNow = (date?: string) =>
    Math.abs(moment(convertUtcToTimezone(date)).diff(moment()));

  const handleDuration = useCallback((array: IDataCourses[], type: string) => {
    let result: IDataCourses | undefined = undefined;

    if (array.length > 0) {
      result = array.reduce((accumulator, currentValue) => {
        if (type === ETrainingLabel.Assigned) {
          const durationCurrentDate = durationToNow(currentValue.start_date);
          const durationPreviousDate = durationToNow(accumulator.start_date);
          if (durationCurrentDate < durationPreviousDate) {
            accumulator = currentValue;
          }
        }
        if (type === ETrainingLabel.Outstanding) {
          if (moment(currentValue.end_date).utc().isBefore(moment(accumulator.end_date).utc())) {
            accumulator = currentValue;
          }
        }
        return accumulator;
      }, array[0]);
    }

    return result;
  }, []);

  const defaultDetailValue = useMemo(() => {
    if (dataCourses.length > 0) {
      const overdueArr: IDataCourses[] = [];
      const assignedArr: IDataCourses[] = [];
      dataCourses.forEach((item) => {
        if (item.training_label === ETrainingLabel.Outstanding) {
          overdueArr.push(item);
        }
        if (item.training_label === ETrainingLabel.Assigned) {
          assignedArr.push(item);
        }
      });
      if (overdueArr.length > 0) return handleDuration(overdueArr, ETrainingLabel.Outstanding);
      else return handleDuration(assignedArr, ETrainingLabel.Assigned);
    }
    return undefined;
  }, [dataCourses, handleDuration]);

  useEffect(() => {
    if (defaultDetailValue && defaultDetailValue.campaign_id) {
      setCourseActive(defaultDetailValue.campaign_id);
      const params = {
        student_id: defaultDetailValue.student_id,
        campaign_id: defaultDetailValue.campaign_id
      };
      dispatch(trainingCourseDetailEvents(params));
    } else {
      setCourseActive(-1);
    }
  }, [dispatch, currentMonth]);

  useEffect(() => {
    setDateFlag(moment())
  }, [getCurrentTimeZone()]);

  const paramsSixMonth = (currentDateParam?:moment.Moment ) => {
    const currentTimeZone = getCurrentTimeZone();
    moment.tz.setDefault(currentTimeZone);
    const currentDate = currentDateParam ? moment(currentDateParam).tz(currentTimeZone) : moment().tz(currentTimeZone)
    const currentMonth =
      moment(currentDate).month() > 5 ? moment(currentDate).startOf('year').add(6, 'months') : moment(currentDate).startOf('year');
    const startSixMonth = moment(currentMonth).utc().format(FORMAT_MOMENT.DATE_TIME_DASH);
    const endSixMonth = moment(currentMonth)
      .add(5, 'months')
      .endOf('month')
      .utc()
      .format(FORMAT_MOMENT.DATE_TIME_DASH);
    return {
      start_date: startSixMonth,
      end_date: endSixMonth
    };
  };

  const findMonthDataWithoutCompleted = async () => {
    try {
      while (!shouldStop) {
        const sixM = paramsSixMonth(dateFlag);
        const response = await dispatch(trainingCoursesQuantityAllEvents(sixM));
        const updateDateFlag = dateFlag.subtract(6, 'month')
        if (response && !response.payload?.has_incomplete_date && moment2.isSameOrBefore(dateFlag)) {
          setDateFlag(updateDateFlag)
          setShouldStop(false);
        } else {
          if(response && response.payload?.has_incomplete_date){
            const time1 = `${response.payload?.has_incomplete_date}Z`;
            const time2 = moment(time1);
            await dispatch(trainingCoursesQuantityEvents(paramsSixMonth(time2)));
            setCurrentCourseQuantityRange(paramsSixMonth(time2))
            setImDate(time2.format())
            setShouldStop(true)
              dispatch(trainingCoursesByDateEvents(paramsOneMonth(time2))).then(() => {
                setCurrentMonth(paramsOneMonth(time2));
             });
             break;
          }else {
            dispatch(trainingCoursesByDateEvents(paramsOneMonth())).then(() => {
              setCurrentMonth(paramsOneMonth());
           });
            setShouldStop(true)
            break;
          }
        }
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <Box className={'card-detail'}>
        <Grid container spacing={'20px'}>
          <Grid item xs={12} sm={12} tablet={12} laptop={7} desktop={5}>
            {/*right detail*/}
            <Box component={'div'} className={'box-list-wrapper'}>
              <WeekCalendar
                listCoursePerDay={dataCourses}
                showDetailsHandle={(start: string, end: string) => showDetailsHandle(start, end)}
                onChangeWeek={(startDate: string, endDate: string) =>
                  changeWeekHandle(startDate, endDate)
                }
                imCompleteDate={imDate}
              />
              <Box component={'div'} className={'box-list'}>
                {dataCourses.length > 0
                  ? dataCourses.map((course, index: number) => {
                    return (
                      <HorizontalCard
                        horizontalCardData={course}
                        key={index}
                        className={courseActive === course.campaign_id ? 'active' : ''}
                        onSelected={() => setCourseActive(course?.campaign_id ?? -1)}
                        clickToDetail={() => courseDetailHandler(course)}
                      />
                    );
                  })
                  : null}
                <Dialog
                  open={openPopup}
                  onClose={closeDetailHandler}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  className={'modal-dialog-course-detail'}
                  fullScreen={true}
                >
                  <Box className={'modal-wrapper'}>
                    <div className={'exit-block-wrapper'}>
                      <Button className={'exit-button'} onClick={closeDetailHandler}>
                        <CloseIcon className={'close-icon'} />
                      </Button>
                    </div>
                    <div className={'left-card-detail-mobile'}>
                      <LeftCardDetail leftCardDetailsData={courseDetailData} />
                    </div>
                  </Box>
                </Dialog>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            tablet={12}
            laptop={5}
            desktop={7}
            className={'is-mobile-course-detail'}
          >
            {/*All Completed Course Component*/}
            {courseActive === -1 ? (
              <LeftCardCompleted
                image={CertificateArtwork}
                contents={'You have completed all ‘Assigned Courses’ by your company Admin.'}
              />
            ) : (
              <Item className={'wrapper-detail'}>
                <LeftCardDetail leftCardDetailsData={courseDetails} />
              </Item>
            )}
          </Grid>

        </Grid>
      </Box>

      <LoadingPage open={(isLoading || courseLoading) && !isLoadingCoursesQuantity} />
    </>
  );
};
export const CardDetail = ChangeWorkSpace(CardDetailComponent);

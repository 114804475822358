import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { addDashboardAccept, getDashboardAccept } from '../api/platform';
export interface UserAcceptEventsObjectState {
  dataUserAccept: {
    isAccept: boolean;
  };
  isLoading: boolean;
  error: string;
}

const initialState: UserAcceptEventsObjectState = {
  dataUserAccept: {
    isAccept: false
  },
  isLoading: false,
  error: ''
};

export const getUserAcceptEvents: any = createAsyncThunk(
  'getUserAcceptEventsPlatform',
  async () => {
    const response = await getDashboardAccept();
    return {
      isAccept: response
    };
  }
);

export const addUserAcceptEvents:any = createAsyncThunk(
  'addUserAcceptEventsPlatform',
  async () => {
    const response = await addDashboardAccept();
    return {
      isAccept: response
    };
  }
);

const userAcceptSlice = createSlice({
  name: 'getUserAcceptEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAcceptEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getUserAcceptEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataUserAccept = action.payload;
      state.error = '';
    });

    builder.addCase(getUserAcceptEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.dataUserAccept.isAccept = false;
      state.error = action.payload as string;
    });

    builder.addCase(addUserAcceptEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addUserAcceptEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataUserAccept = action.payload;
      state.error = '';
    });

    builder.addCase(addUserAcceptEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.dataUserAccept.isAccept = false;
      state.error = action.payload as string;
    });
  }
});

// export const {} = userAcceptSlice.actions;

export const userAcceptEventsDataSelector = (state: RootState) => state.userAcceptEvents;
export default userAcceptSlice.reducer;

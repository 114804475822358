import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
  getCourseCategories,
  getCourseDetails,
  getListGradeCourseResults,
  PaginatedData
} from '../api/platform';
import { IDataFilter } from '../components/SearchMenuBar';
import { GridSortDirection } from '@mui/x-data-grid';

export enum GradeStatus {
  assigned = 'Assigned',
  failed = 'Failed',
  outstanding = 'Outstanding',
  passed = 'Passed'
}

export interface CourseGradesParams {
  per_pages: number;
  page: number;
  keyword: string;
  category: string[];
  sort_type: GridSortDirection;
  sort_column: string;
}

export interface CourseGrades {
  course_name: string;
  course_category: string;
  grade: string;
  has_cert: number;
  status: GradeStatus;
  id: number;
  campaign_id: number;
  phish_id: number;
}

export interface gradeCourseResultsEventsObjectState {
  listGradeCourseResults: PaginatedData<CourseGrades>;
  courseDetails: any;
  isLoading: boolean;
  isLoadingCategories: boolean;
  error: undefined;
  courseCategories: IDataFilter[];
}

const initialState: gradeCourseResultsEventsObjectState = {
  listGradeCourseResults: {
    total_items: 0,
    total_page: 0,
    start_item: 0,
    end_item: 0,
    list: []
  },
  courseDetails: {},
  isLoading: false,
  isLoadingCategories: false,
  error: undefined,
  courseCategories: []
};

export const gradeCourseResultsEvents = createAsyncThunk(
  'gradeCourseResultsEventPlatform',
  async (params: CourseGradesParams) => {
    const response = await getListGradeCourseResults(params);
    return response ?? {};
  }
);

export const getCourseDetailsEvent = createAsyncThunk('getCourseDetails', async (id: string) => {
  const response = await getCourseDetails(id);
  return response.data ?? {};
});

export const getCourseCategoriesEvent = createAsyncThunk('getCourseCategories', async () => {
  const response = await getCourseCategories();
  return response.data ?? [];
});

export const gradeCourseResultsSlice = createSlice({
  name: 'gradeCourseResultsEvents',
  initialState,
  reducers: {
    setGradeCourseResultsEventData: (state: any, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.listGradeCourseResults = action.payload;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(gradeCourseResultsEvents.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });

    builder.addCase(gradeCourseResultsEvents.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.listGradeCourseResults = action.payload;
      state.error = undefined;
    });

    builder.addCase(gradeCourseResultsEvents.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.listGradeCourseResults = undefined;
      state.error = action.payload as string;
    });

    builder.addCase(getCourseDetailsEvent.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCourseDetailsEvent.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.courseDetails = action.payload;
      state.error = undefined;
    });

    builder.addCase(getCourseDetailsEvent.rejected, (state: any, action: any) => {
      state.isLoadingCategories = false;
      state.courseDetails = undefined;
      state.error = action.payload as string;
    });

    builder.addCase(getCourseCategoriesEvent.pending, (state) => {
      state.isLoadingCategories = true;
    });

    builder.addCase(getCourseCategoriesEvent.fulfilled, (state: any, action: any) => {
      state.isLoadingCategories = false;
      state.courseCategories = action.payload.map((item: any, index: number) => ({
        id: index,
        content: item
      }));
      state.error = undefined;
    });

    builder.addCase(getCourseCategoriesEvent.rejected, (state: any, action: any) => {
      state.isLoadingCategories = false;
      state.courseCategories = undefined;
      state.error = action.payload as string;
    });
  }
});

export const { setGradeCourseResultsEventData } = gradeCourseResultsSlice.actions;

export const gradeCourseResultsEventsDataSelector = (state: RootState) =>
  state.gradeCourseResultsEvents;

export default gradeCourseResultsSlice.reducer;

import { useCallback, useEffect, useState } from 'react';
import { Hub, Auth } from 'aws-amplify';

export const AuthenticatedStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

  const ionViewCanEnter = useCallback(async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser !== undefined) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch {
      setIsAuthenticated(undefined);
    }
  }, []);

  useEffect(() => {
    ionViewCanEnter();
  }, [ionViewCanEnter]);

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn' || 'autoSignIn' || 'tokenRefresh':
          setIsAuthenticated(true);
          break;
        case 'signOut' || 'signIn_failure' || 'tokenRefresh_failure' || 'autoSignIn_failure':
          console.log('is not authenticated');
          setIsAuthenticated(false);
          break;
      }
    };

    Hub.listen('auth', listener);
  }, []);

  return isAuthenticated;
};

import React from 'react';
import { FooterProps } from '.';
import './Footer.css';

export const Footer: React.FC<FooterProps> = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="fixed">
      <span className=" copyright-sign">&#169; {currentYear} Phriendly Phishing</span>
    </footer>
  );
};

import React, { FC } from 'react';
import './Error404.css';
import { Typography } from '@mui/material';
import { Footer } from '../../container/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import {Error404Props} from "./Error404.props";
import {Auth} from "aws-amplify";


export const Error404: FC<Error404Props> = () => {
  const navigate = useNavigate();
  const handleClick = async () => {
    navigate('/login');
    localStorage.clear();
    await Auth.signOut();
  };
  return (
    <div className={'logout-page'}>
      <div className={'body'}>
        <div className={'body-content'}>
          {/*<img src={Logout} alt="Logout icon" className={'logo-logout'} />*/}
          <Typography className={'title'}>404</Typography>
          <Typography className={'guideline-text'} sx={{width:'118%'}}>The page you are looking for cannot be found</Typography>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick()}
            className={'portal-name'}
          >
            Learner Hub login portal
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
};
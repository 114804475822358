import React from 'react';
import styles from './FieldControl.module.css';
import { Input } from '@mui/material';
import { styled } from '@mui/material/styles';

const CssTextField = styled(Input)({
  '& label.Mui-focused': {
    color: '#fff'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#fff'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#fff'
    },
    '&:hover fieldset': {
      borderColor: '#fff'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff'
    }
  }
});
export const FieldControl: React.FC<any> = ({ label,helperText, ...inputProps }) => (
  <div className={styles['field-control']}>
    <label>{label}</label>
    <CssTextField
      sx={{ backgroundColor: '#fff', width: '100%', borderRadius: '4px' }}
      label=""
      {...inputProps}
    />
    {helperText && <p className={styles['helper-text-input']}>{helperText}</p>}
  </div>
);

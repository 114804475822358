import React, { useEffect, useState } from 'react';
import { SearchMenuBarProps } from './SearchMenuBar.props';
import { Button, Drawer, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SearchFilter } from '../SearchFilter';
import './SearchMenuBar.css';
import './styles/mobile.css';
import { Close, FilterList } from '@mui/icons-material';
import DropDownFilter from '../DropDownFilter/DropDownFilter';

export const SearchMenuBar: React.FC<SearchMenuBarProps> = ({
  onFilter,
  handleEnterSearch,
  selectedItems,
  resetFilter = () => undefined,
  searchInputValue,
  setSearchInputValue,
  onClearFilter,
  totalDataFilter = [],
  mobileDrawerIcon,
  mobileDrawerTitle
}) => {

 const [isShowFilter] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<any>({ category: null, priority: null, scans: null });
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const url = window.location.pathname;

  // use effect close dropdown filter
  useEffect(() => {
    setIsOpen({ category: null, priority: null, scans: null });
  }, [url]);

  const dropdownList = () => {
    if (totalDataFilter.length > 0) {
      return totalDataFilter.map((item, index: number) => {
        return (
          <DropDownFilter
            key={`${index} ${item.key}`}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            clearFilerSelection={onClearFilter}
            onSelectItem={onFilter}
            selectedItems={selectedItems}
            label={item.label ? item.label : item.key}
            type={item.key}
            arrList={item.data}
            disable={item.isDisable}
            defaultArrListToShowSearchField={totalDataFilter[index].data}
          />
        );
      });
    }
    return null;
  };

  const MobileDrawer: React.FC = () => (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      sx={{
        '.MuiPaper-root': {
          width: '100%'
        }
      }}
    >
      <Stack
        padding="15px 22px 0 23px"
        spacing={6.25}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          height: '100%'
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1.25}>
            {mobileDrawerIcon}
            <Typography fontSize={18} fontWeight={600}>
              {mobileDrawerTitle}
            </Typography>
          </Stack>
          <div onClick={() => setDrawerOpen(false)}>
            <Close />
          </div>
        </Stack>
        <Stack spacing={1.25}>{dropdownList()}</Stack>
        <Stack spacing={1.25}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            id="mobile-clear-btn"
            sx={{
              backgroundColor: 'white',
              color: '#345C8B',
              ':hover': {
                backgroundColor: 'white'
              }
            }}
          >
            Clear Filters
          </Button>
          <Button
            onClick={() => setDrawerOpen(false)}
            sx={{
              backgroundColor: '#345C8B',
              color: 'white',
              ':hover': {
                backgroundColor: '#345C8B'
              }
            }}
          >
            Apply Filter
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );

  return isMobile ? (
    <Stack direction="row" justifyContent="center">
      <SearchFilter
        height={38}
        margin={'0'}
        widthTextInput={180}
        titleInput={'Search'}
        borderRadius={'4px'}
        handleKeyDownEvent={handleEnterSearch}
        inputValue={searchInputValue}
        setInputValue={setSearchInputValue}
      />
      <Button
        onClick={() => setDrawerOpen(!drawerOpen)}
        variant="outlined"
        sx={{ marginLeft: '10px' }}
      >
        <FilterList />
      </Button>
      <MobileDrawer />
    </Stack>
  ) : (
    <div className={'search-menu'}>
      <SearchFilter
        data-testid={'search-filter'}
        height={38}
        margin={'0'}
        widthTextInput={180}
        titleInput={'Search by keyword'}
        borderRadius={'4px'}
        handleKeyDownEvent={handleEnterSearch}
        inputValue={searchInputValue}
        setInputValue={setSearchInputValue}
      />

      <div className={'search-menu-right ' + (isShowFilter ? 'show-filter ' : 'hide-filter')}>
        {dropdownList()}
        <div className={'bottom-buttons-popup'}>
          <Button
            data-testid={'btn-reset'}
            variant="text"
            onClick={() => resetFilter()}
            className={'btn-reset-filter'}
            disableRipple={true}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

import React, { useCallback } from 'react';
import { SearchFilterProps } from './SearchFilter.props';
import { InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './SearchFilter.css';

export const SearchFilter: React.FC<SearchFilterProps> = ({
  titleInput,
  handleKeyDownEvent,
  inputValue,
  setInputValue = () => undefined
}) => {
  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  return (
    <OutlinedInput
      data-testid="search-filter"
      sx={{
        width: '248px',
        borderRadius: '5px',
        borderColor: '#fff',
        backgroundColor: '#fff'
      }}
      placeholder={titleInput || 'Search'}
      onChange={(e) => handleChangeInput(e)}
      onKeyDown={handleKeyDownEvent}
      value={inputValue}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

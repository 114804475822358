import React from 'react';
import { LoadingPageProps } from './LoadingPage.props';
import './LoadingPage.css';
import Backdrop from '@mui/material/Backdrop';
import { images } from '../../constants/images';

export const LoadingPage: React.FC<LoadingPageProps> = ({ open = false }) => {
  return (
    <Backdrop id={'loading-page'} sx={{ color: '#fff' }} open={open}>
      <img src={images.loadingPageIcon} alt="loadingPageIcon" />
    </Backdrop>
  );
};

import { useAppSelector } from '../redux/hooks';
import { workSpaceEventsDataSelector } from '../redux/workSpaceSlice';
import React, { Fragment, useEffect, useState } from 'react';

export default function ChangeWorkSpace<T>(Component: React.ComponentType<T>) {
  const CE: React.FC<T> = (props) => {
    const { workSpaceCurrentData } = useAppSelector(workSpaceEventsDataSelector);
    const [currentCell, setCurrentCell] = useState<number | undefined>(undefined);
  
    useEffect(() => {
      if (workSpaceCurrentData && currentCell !== workSpaceCurrentData.cell_id) {
        setCurrentCell(workSpaceCurrentData.cell_id);
      }
    }, [currentCell, workSpaceCurrentData]);

    return (
      <Fragment key={workSpaceCurrentData?.cell_id}>
        {<Component currentCell {...props} />}
      </Fragment>
    );
  };
  return CE;
}

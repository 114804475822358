export const AMPLIFY_CONFIG = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_PH2_DOMAIN_USER_POOL,
      scope: ['aws.cognito.signin.user.admin','email','openid'],
      redirectSignIn: process.env.REACT_APP_PH2_REDIRECT_URI+ '/courses',
      redirectSignOut: process.env.REACT_APP_PH2_REDIRECT_URI + '/logout',
      responseType: 'code'
    }
  }
};

import React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import { WorkSpaceProps } from './WorkSpace.props';
import { ReactComponent as ActiveBox } from '../../assets/images/acctive-box.svg';
import { ReactComponent as InActiveBox } from '../../assets/images/inactive-box.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './WorkSpace.css';
import { IWorkSpaceData } from '../../redux/workSpaceSlice';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
        paddingLeft: '10px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      },
        'svg': {
            marginRight: '10px',
            marginTop: '1px'
        },
        '& .work-space-item':{
            width: '100%',
            display:"flex",
            alignItems:'center',
            lineHeight:'26px'
        }
    }
  }
}));
export const WorkSpace: React.FC<WorkSpaceProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { workSpaceData = [], currentCell, setCurrentCell = () => undefined } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = (val: IWorkSpaceData) => {
    setAnchorEl(null);
    localStorage.setItem('currentCell', JSON.stringify(val));
    setCurrentCell(val);
  };

  return (
    <div className="workspace-block">
      <Button
        id="work-space-button"
        data-testid={"work-space-button"}
        className={`workspace-icon ${ open ? 'active' : ''}`}
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon sx={{color: isTablet ? 'black':'white'}} /> }
      >
        <span
          style={{
            color: isTablet ? 'black' : 'white',
            fontSize: '14px',
            fontFamily: 'Righteous',
            fontWeight: '400'
          }}
        >
            {props.userName}
        </span>
      </Button>
      <StyledMenu
        id="work-space-menu"
        data-testid={"work-space-menu"}
        MenuListProps={{
          'aria-labelledby': 'work-space-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {workSpaceData.map((workSpace, index: number) => (
          <MenuItem key={index} onClick={() => onClickItem(workSpace)} disableRipple>
            {/*{currentCell?.cell_id === workSpace.cell_id ? (*/}
            {/*  <span style={{ width: '100%' }}>*/}
            {/*    {workSpace?.cell_name}*/}
            {/*    <Check sx={{ float: 'right', marginRight: '-19px !important' }} />*/}
            {/*  </span>*/}
            {/*) : (*/}
            {/*  <span>{workSpace?.cell_name}</span>*/}
            {/*)}*/}

              {currentCell?.cell_id === workSpace.cell_id ? (
                  <span className={'work-space-item'} style={{ }}>
                      <ActiveBox />
                      {workSpace?.cell_name}
              </span>
              ) : (
                  <span className={'work-space-item'} style={{ width: '100%' }}>
                     <InActiveBox />
                      {workSpace?.cell_name}
              </span>
              )}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

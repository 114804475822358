import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Auth } from 'aws-amplify';

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

let accessToken: string | void;

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  config.headers['Authorization'] = accessToken || ``;
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  const originalRequest: any = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    getAccessJwtToken()
      .then((token: any) => {
        accessToken = token;
        axios.defaults.headers.common['Authorization'] = `${token}`;
        return axios(originalRequest);
      })
      .catch((err) => console.log(err));
  }

  return Promise.reject(error);
};

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

export const httpClient = (url: string, method: HttpMethod, data: any) => {
  return new Promise((resolve, reject) => {
    const baseURL = process.env.REACT_APP_BASE_API_URL;
    const params: any = {
      method,
      url: `${baseURL}${url}`,
      headers: {'Content-Type': 'application/json'}
    };
    if (data) {
      params.data = data;
    }
    getIdTokenAmlify(url).then((token: any) => {
      accessToken = token;
      axios(params)
        .then((response) => {
          if (response.status > 199 && response.status < 300) {
            resolve(response.data);
          } else {
            reject('Response is not success');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const getAccessJwtToken = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const { refreshToken } = cognitoUser.getSignInUserSession();
  return new Promise((resolve) => {
    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
      // const newToken = session.getIdToken().getJwtToken();
      const newToken = session.getAccessToken().getJwtToken();
      resolve(newToken);
    });
  });
};

const getIdTokenAmlify = async (url: string) => {
  // if (url !== '/auth/get_users_in_system') {
  if (url && !url.includes('/auth/')) {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  }
  return `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`;
};

import React, { useCallback, useEffect, useState } from 'react';
import './Header.css';
import { images } from '../../constants/images';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  // Badge,
  // badgeClasses,
  Box,
  Stack,
  Typography
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { CONSTANTS } from '../../constants/constants';
import { WorkSpace } from '../../components/Workspace';
import {
  IWorkSpaceData,
  removeWorkSpace,
  setWorkSpaceEventData,
  workSpaceEvents,
  workSpaceEventsDataSelector
} from '../../redux/workSpaceSlice';
// import {getUserData} from "../../api/platform";
import { logoutEvents } from '../../redux/logoutSlice';
//import MenuIcon from '@mui/icons-material/Menu';
//import { Box, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  // notificationActions,
  notificationsDataSelector
} from '../../redux/notificationsSlice';
import { getCurrentCellStore, getUserName } from '../../utils/util';
import { UserAvatarLetters } from '../../components/UserAvatarLetters/UserAvatarLetters';
import {getUserAcceptEvents, userAcceptEventsDataSelector} from '../../redux/userAcceptSlice';
import {removeTrainingCoursesByDateEvent} from "../../redux/trainingCoursesByDateSlice";
import {removeTrainingCourseDetailEvent} from "../../redux/trainingCourseDetailSlice";

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { dataUserAccept } = useAppSelector(userAcceptEventsDataSelector);
  const { workSpaceData } = useAppSelector(workSpaceEventsDataSelector);
  const [workSpace, setWorkSpace] = useState<IWorkSpaceData[]>([]);
  const [currentCell, setCurrentCell] = useState<IWorkSpaceData | undefined>(undefined);
  const [notiAnchorEl, setNotiAnchorEl] = React.useState<null | HTMLElement>(null);
  const notificationsAnchor = Boolean(notiAnchorEl);
  const [mobileNav, setMobileNav] = useState<boolean>(false);
  const { notifications } = useAppSelector(notificationsDataSelector);
  const userCurrentCellStore = getCurrentCellStore();
  const userData = { fullname: 'Learner', avatar: images.NoAvatar };
  const [isShow, setIsShow] = useState(true);
  const pathNameBtn = location.pathname.includes('/courses') ? '/courses' : location.pathname;

  const onClickCell = (cellData: IWorkSpaceData) => {
    setCurrentCell(cellData);
    dispatch(setWorkSpaceEventData(cellData));
    if(location.pathname.includes('/overview/')){
      navigate( '/courses',{
        state:{ 
          tabIndex: 2, // when workspace changed, system navigates to Results page
          subTabIndex: 0}
        }
      )
    }
    if(location.pathname.includes('/catalogue-overview/')){
      navigate( '/courses',{
          state:{
            tabIndex: 1, // when workspace changed, system navigates to Results page
            subTabIndex: 0}
        }
      )
    }
  };

  const handleBellClose = async () => {
    setNotiAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {

      dispatch(logoutEvents(true));
      dispatch(removeWorkSpace())
      dispatch(removeTrainingCoursesByDateEvent())
      dispatch(removeTrainingCourseDetailEvent())
      await Auth.signOut();
      navigate('/logout');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const handleHeaderNavigation = (path: string) => {
    navigate(path);
  };

  const getAcceptBtn = useCallback(async () => {
    dispatch(getUserAcceptEvents());
  }, [dispatch]);

  useEffect(() => {
    getAcceptBtn();
  }, [getAcceptBtn]);


  const checkAuthen = async () =>{
    try{
      const userEmail = localStorage.getItem('userEmail') || '';
      const isAuthen = await Auth.currentAuthenticatedUser() || '';
      console.log('currentAuthenticatedUser: ', isAuthen);
      console.log('currentAuthenticatedUser - Email: ', isAuthen?.attributes?.email?.toLowerCase());
      console.log('userEmail from login: ', userEmail);
      if(isAuthen && (isAuthen?.attributes?.email?.toLowerCase() === userEmail)) {
        console.log('userEmail === Email ');
        localStorage.setItem('isAuth', 'true')
      } else{
        navigate('/page404')
      }
    } catch(e:any){
        console.log('AuthenticatedUserError: ', e);
    }
  }

  useEffect(() => {
    checkAuthen()
  }, []);

  useEffect(() => {
    if (workSpaceData.length > 0) {
      localStorage.removeItem('currentCell');
      setWorkSpace(workSpaceData);
      setCurrentCell(workSpaceData[0]);
      localStorage.setItem('currentCell', JSON.stringify(workSpaceData[0]));
      dispatch(setWorkSpaceEventData(workSpaceData[0]));
    } else {
      setCurrentCell(undefined);
      localStorage.removeItem('currentCell');
    }
  }, [workSpaceData, dispatch]);

  const getWorkSpace = async ()=> {
    const userEmail = localStorage.getItem('userEmail') || '';
    if (dataUserAccept.isAccept && userEmail) {
      await dispatch(workSpaceEvents()).then((res: any)=>{
        console.log('getWorkSpaceData: ', res?.payload);
        if(res && Array.isArray(res.payload) && res.payload.length < 1 && !(location.pathname.includes('/dashboard'))){
          navigate('/unauthorized');
        }
      }).catch(() => {
        if(!(location.pathname.includes('/dashboard'))){
          navigate('/unauthorized');
        }
        console.log('getWorkSpaceError');
      });
    }
  }

  const getsUerNameWorkSpace=()=>{
    const userName = getUserName(userCurrentCellStore)
    if(userName && dataUserAccept.isAccept){
        return userName;
    }
    return userData.fullname;
  }

  useEffect(  () => {
    getWorkSpace()
  }, [dataUserAccept.isAccept, dispatch]);

  useEffect(() => {
    setIsShow(!location.pathname.includes('/unauthorized'));
    checkAccept() // check isAccept to navigate to courses link when user uses url on UI
  }, [location.pathname]);

  const checkAccept = async ()=>{
    const isLoacationCourse = location.pathname.includes('/courses')
    const data = await dispatch(getUserAcceptEvents());
    console.log('getUserAccept-check Accept: ', data?.payload?.isAccept);
    if(!data?.payload?.isAccept && isLoacationCourse){
      navigate('/dashboard')
    }
  }

  return (
    <>
      {isShow ? (
        <header id="header" className="header fixed-top">
          <img className="header-logo" src={images.Ph2Logo} alt="logo" />
          <div className="hamburger-menu" onClick={() => setMobileNav(!mobileNav)}></div>
          <div className="header-center">
            {CONSTANTS.NAVIGATE &&
              Object.keys(CONSTANTS.NAVIGATE).map((route, index: number) => {
                const newRoute = route as keyof typeof CONSTANTS.NAVIGATE;
                const Icon = CONSTANTS.NAVIGATE[newRoute].icon;
                return (
                  <div
                    key={index}
                    className={
                      'btn-navigation ' +
                      (pathNameBtn === CONSTANTS.NAVIGATE[newRoute].path ? 'active' : '')
                    }
                  >
                    <Button
                      onClick={() => handleHeaderNavigation(CONSTANTS.NAVIGATE[newRoute].path)}
                      variant="outlined"
                      sx={{ width: 160 }}
                      color="inherit"
                      disabled={route !== 'HOME' ? (dataUserAccept?.isAccept && currentCell?.cell_id ? false :true) : false}
                    >
                      <span>
                        <Icon />
                      </span>
                      {CONSTANTS.NAVIGATE[newRoute].name}
                    </Button>
                  </div>
                );
              })}
            {/*<Badge*/}
            {/*    color="error"*/}
            {/*    className={'header-badge-notification-mobile'}*/}
            {/*    invisible={!unread}*/}
            {/*    badgeContent={unread}*/}
            {/*    onClick={e => handleBellClick(e)}*/}
            {/*    sx={{*/}
            {/*      [`& .${badgeClasses.dot}`]: {*/}
            {/*        width: 13,*/}
            {/*        height: 13,*/}
            {/*        borderRadius: "50%"*/}
            {/*      },*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <Icon*/}
            {/*      sx={{color: 'white', width: 24, height: 25, marginTop:'2px'}}*/}
            {/*  >*/}
            {/*    <NotificationsNoneOutlinedIcon*/}
            {/*        sx={{fontSize: 27}}*/}
            {/*    />*/}
            {/*  </Icon>*/}
            {/*</Badge>*/}
          </div>
          <div className={'header-right ' + (mobileNav ? 'show' : '')}>
            <div className="is-mobile close-icon" onClick={() => setMobileNav(false)}>
              <CloseIcon />
            </div>
            <div className="profile-block">
              {getUserName(userCurrentCellStore) && dataUserAccept.isAccept ? (
                <UserAvatarLetters name={getUserName(userCurrentCellStore)} />
              ) : (
                <div className="profile-avatar">
                  <img className="profile-picture" src={userData.avatar} alt="avatar" />
                </div>
              )}
              <span className="full-name">
                {(dataUserAccept?.isAccept && currentCell?.cell_id && workSpace?.length > 1) ? (
                  <WorkSpace
                    workSpaceData={workSpace}
                    currentCell={currentCell || undefined}
                    setCurrentCell={(value) => onClickCell(value)}
                    userName={getsUerNameWorkSpace()}
                  />
                ) : getsUerNameWorkSpace()}
              </span>
              <div className="logout-icon">
                <LogoutIcon
                  onClick={() => handleSignOut()}
                  sx={{
                    color: 'white',
                    marginLeft: 1.25,
                    cursor: 'pointer'
                  }}
                />
              </div>
            </div>
            {/*<Badge*/}
            {/*    color="error"*/}
            {/*    className={'header-badge-notification'}*/}
            {/*    invisible={!unread}*/}
            {/*    onClick={e => handleBellClick(e)}*/}
            {/*    badgeContent={unread}*/}
            {/*    sx={{*/}
            {/*      [`& .${badgeClasses.dot}`]: {*/}
            {/*        width: 13,*/}
            {/*        height: 13,*/}
            {/*        borderRadius: "50%"*/}
            {/*      },*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <Icon*/}
            {/*      sx={{color: 'white', width: 24, height: 25, marginTop:'2px'}}*/}
            {/*  >*/}
            {/*    <NotificationsNoneOutlinedIcon*/}
            {/*        sx={{fontSize: 27}}*/}
            {/*    />*/}
            {/*  </Icon>*/}
            {/*</Badge>*/}

            <div className="logout-mobile is-mobile">
              <Stack
                direction="row"
                onClick={() => handleSignOut()}
                padding="8px 0"
                spacing={1}
                borderRadius="4px"
                color="black.light"
                sx={{
                  ':active': {
                    background: '#9FD8DB',
                    color: '#345C8B',
                    fontWeight: 700,
                    borderLeft: '3px solid #345C8B'
                  }
                }}
              >
                <LogoutIcon />
                <Typography fontWeight="inherit">Log Out</Typography>
              </Stack>
              <Stack
                className="menu-bottom-mobile"
                direction="row"
                height={46}
                padding="12px 5px 0 5px"
                borderTop="1px solid var(--decoration-colours-more-black-20, #CCCFCF)"
                justifyContent="space-between"
              >
                <img src={images.Ph2LogoDark} alt="log" />
                <Stack justifyContent="center">
                  <Typography fontSize={12} fontWeight={500}>
                    &#169; {new Date().getFullYear()} Phriendly Learners Hub
                  </Typography>
                  <Typography fontSize={12} fontWeight={500} textAlign="right">
                    Terms of use
                  </Typography>
                </Stack>
              </Stack>
            </div>
            <Box>
              <Menu
                anchorEl={notiAnchorEl}
                id="notification-menu"
                open={notificationsAnchor}
                onClose={handleBellClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Box>
                        <Typography variant="h6">{notification.title}</Typography>
                      </Box>
                      <Typography variant="body1">{notification.body}</Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <Typography>Empty</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </div>
        </header>
      ) : null}
    </>
  );
};

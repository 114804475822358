import React from 'react';
import './PageUnAuthorized.css';
import { images } from '../../constants/images';
import { useNavigate } from 'react-router-dom';
export const PageUnAuthorized: React.FC = () => {
  const navigate = useNavigate();
  const backToLogin = () => {
    navigate('/dashboard');
  };
  return (
    <div className={'notfound'}>
      <div style={{ padding: '20px' }}></div>
      <div className={'center'}>
        <img width={'100%'} src={images.NotFoundIcon} />
        <p style={{ width: '100%', fontWeight: 700, fontSize: '32px', color: '#9cc145' }}>
          FORBIDDEN <br /> 403{' '}
        </p>
        <p style={{ width: '100%', fontWeight: 700, fontSize: '18px', color: '#73a2ae' }}>
          "We are sorry, but you do not have access to this page or resource."
          <br />
          <span
            style={{ cursor: 'pointer', color: 'white', fontSize: '14px' }}
            onClick={() => backToLogin()}
          >
            Back to Dashboard
          </span>
        </p>
      </div>
      <div className={'wave'}>
        <img src={images.Wave} width={'100%'} />
      </div>
    </div>
  );
};

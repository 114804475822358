import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { DateRangeParams, getListTrainingCoursesQuantity } from '../api/platform';
export interface ICoursesQuantityData {
  month: string;
  value: {
    total: number;
    has_incomplete: number;
  };
}
export interface TrainingCoursesEventsObjectState {
  coursesQuantityData: ICoursesQuantityData[];
  isLoadingCoursesQuantity: boolean;
  error?: string;
}

const initialState: TrainingCoursesEventsObjectState = {
  coursesQuantityData: [],
  isLoadingCoursesQuantity: false,
  error: ''
};

export const trainingCoursesQuantityEvents = createAsyncThunk(
  'get/trainingCoursesQuantityEvents',
  async (params: DateRangeParams) => {
    const response = await getListTrainingCoursesQuantity(params);
    const data = response.data;
    return data
      ? Object.keys(data).map((key) => {
          return {
            month: key,
            value: data[key]
          };
        })
      : [];
  }
);

export const trainingCoursesSlice = createSlice({
  name: 'trainingCoursesEvents',
  initialState,
  reducers: {
    setTrainingCoursesEventData: (state, action: PayloadAction<ICoursesQuantityData[]>) => {
      state.isLoadingCoursesQuantity = false;
      state.coursesQuantityData = action.payload;
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(trainingCoursesQuantityEvents.pending, (state) => {
      state.isLoadingCoursesQuantity = true;
    });

    builder.addCase(
      trainingCoursesQuantityEvents.fulfilled,
      (state, action: PayloadAction<ICoursesQuantityData[]>) => {
        state.isLoadingCoursesQuantity = false;
        state.coursesQuantityData = action.payload;
        state.error = '';
      }
    );

    builder.addCase(trainingCoursesQuantityEvents.rejected, (state, action) => {
      state.isLoadingCoursesQuantity = false;
      state.coursesQuantityData = [];
      state.error = action.payload as string;
    });
  }
});

export const { setTrainingCoursesEventData } = trainingCoursesSlice.actions;

export const trainingCoursesEventsDataSelector = (state: RootState) => state.trainingCoursesEvents;

export default trainingCoursesSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import workSpaceEventsReducer from './workSpaceSlice';
import trainingCoursesReducer from './trainingCoursesSlice';
import trainingCoursesByDateReducer from './trainingCoursesByDateSlice';
import logoutReducer from './logoutSlice';
import trainingCourseDetailReducer from './trainingCourseDetailSlice';
import getCertificateReducer from './getCertificateSlice';
import notificationReducer from './notificationsSlice';
// import websocketMiddleware from './websocketMiddleware';
import websocketReducer from './websocketSlice';
import gradeCourseResultReducer from './getListGradeCourseResultsSlice';
import learnersReducer from './learnersSlice';
import userAcceptReducer from './userAcceptSlice';
import updateLockLearnerReducer from './updateLockLearner';
import courseCatalogueReducer from './courseCatalogueSlice';
import trainingCoursesAllReducer from './trainingCourseQuantityAllSlice';
import courseCategoriesReducer from './courseCategoriesSlice';

export const store = configureStore({
  reducer: {
    workSpaceEvents: workSpaceEventsReducer,
    trainingCoursesEvents: trainingCoursesReducer,
    trainingCoursesByDateEvents: trainingCoursesByDateReducer,
    logoutEvents: logoutReducer,
    trainingCourseDetailEvents: trainingCourseDetailReducer,
    getCertificateEvents: getCertificateReducer,
    notificationEvents: notificationReducer,
    websocketEvents: websocketReducer,
    gradeCourseResultsEvents: gradeCourseResultReducer,
    learnersEvents: learnersReducer,
    userAcceptEvents: userAcceptReducer,
    updateLockLearnerEvents: updateLockLearnerReducer,
    getCourseCatalogueEvents: courseCatalogueReducer,
    trainingCoursesAllEvents: trainingCoursesAllReducer,
    courseCategoriesEvents: courseCategoriesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
  // middleware: (getDefaultMiddleware) =>
  // getDefaultMiddleware({
  //   serializableCheck: false,
  //   immutableCheck: false
  // }).concat([websocketMiddleware])
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

import React from 'react';
import { DataTablesProps } from './DataTables.props';

import { DataGrid } from '@mui/x-data-grid';
import './DataTables.css';

export const DataTables: React.FC<DataTablesProps> = (props) => {
  const {
    tblRows,
    tblColumns,
    loadingTable,
    pageSize,
    onChangePageSize,
    rowPerPages,
    customPaging,
    defaultSortModel,
    totalRows,
    sortModelChangeHandler,
    sortingMode
  } = props;

  return (
    <div className={'data-tables-general'} data-testid='data-tables-test'>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick={true}
        hideFooterSelectedRowCount={true}
        rows={tblRows}
        loading={loadingTable}
        columns={tblColumns}
        pageSizeOptions={rowPerPages}
        onPaginationModelChange={onChangePageSize}
        rowCount={totalRows}
        getRowHeight={() => 'auto'}
        sortingMode={sortingMode}
        onSortModelChange={sortModelChangeHandler}
        slots={{
          pagination: () => customPaging
        }}
        paginationMode={'server'}
        sx={{
          '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            cursor: 'default'
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent'
          },
          '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: 'white'
          },
          '& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '160%',
            fontStyle: 'normal',
            color: '#212121'
          },
          '& .MuiDataGrid-row.Mui-hovered':{
            backgroundColor:'unset'
          }
        }}
        initialState={{
          sorting: {
            sortModel: defaultSortModel
          },
          pagination: {
            paginationModel: {
              pageSize: pageSize,
              page: 0
            }
          }
        }}
      />
    </div>
  );
};

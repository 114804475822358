import React, { FC } from 'react';
import { LeftCardDetailProps } from './LeftCardDetail.props';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { images } from '../../constants/images';
// import TimelapseIcon from '@mui/icons-material/Timelapse';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import moment from 'moment';
import { getCertHandler, parseImgURL, sanitizeHTML } from '../../utils/util';
import './styles/mobile.css';
import './styles/ipad.css';
import './LeftCardDetail.css';

import { useAppSelector } from '../../redux/hooks';
import {
  getCertificateEvents,
  getCertificateEventsDataSelector
} from '../../redux/getCertificateSlice';
import { useDispatch } from 'react-redux';
import { LoadingPage } from '../LoadingPage';
import { convertUtcToTimezone } from '../../utils/dateTimeHelper';
import { FORMAT_DATE, TYPE_LETF_CARD_DETAIL } from '../../constants/constants';
export const LeftCardDetail: FC<LeftCardDetailProps> = (props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const dispatch = useDispatch<any>();
  const { leftCardDetailsData, type } = props;
  const utcByTimezone = convertUtcToTimezone(leftCardDetailsData?.cp_end_date);
  const { isLoading } = useAppSelector(getCertificateEventsDataSelector);
  const learningOutcome = leftCardDetailsData?.tp_learning_outcome || '';
  const courseOverview = leftCardDetailsData?.tp_overview || '';
  /*Removed on MVP*/
  // const renderTrainingLabel = () => {
  //   if (leftCardDetailsData?.training_label) {
  //     return (
  //       <Alert
  //         icon={false}
  //         className={`${leftCardDetailsData.training_label.toLocaleLowerCase()} alert-item`}
  //       >
  //         {leftCardDetailsData.training_label}
  //       </Alert>
  //     );
  //   }
  //   return null;
  // };

  return (
    <>
      {/*Remove in MVP*/}
      {/*<Stack*/}
      {/*  className={'card-detail-alert'}*/}
      {/*  direction={"row"}*/}
      {/*  alignItems={'center'}*/}
      {/*  justifyContent={{md:'space-between', lg:'start', sm:"space-around"}}*/}
      {/*  flexWrap={{md:'wrap'}}*/}
      {/*  sx={{ width: '100%', margin: '20px 0 83px 0' }}*/}
      {/*  spacing={{sm:2, md:0.25, lg:1}}*/}
      {/*>*/}
      {/*  <Alert icon={false} className={'compulsory alert-item'}>*/}
      {/*    Compulsory*/}
      {/*  </Alert>*/}

      {/*  {renderTrainingLabel()}*/}

      {/*  <Alert*/}
      {/*    icon={false}*/}
      {/*    className={`${*/}
      {/*      leftCardDetailsData?.has_certificate === 1 ? 'cert alert-item' : 'no-cert alert-item'*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    {leftCardDetailsData?.has_certificate === 1 ? 'Certificate' : ' No Certificate'}*/}
      {/*  </Alert>*/}

      {/*  {leftCardDetailsData?.assigned_course_again === 1 ? (*/}
      {/*    <Alert icon={false} className={'refresher alert-item'}>*/}
      {/*      Refresher*/}
      {/*    </Alert>*/}
      {/*  ) : (*/}
      {/*    ''*/}
      {/*  )}*/}
      {/*</Stack>*/}

      <Card
        className={'card-detail card-detail-mobile'}
        sx={{ boxShadow: 'none', borderRadius: '0' }}
      >
        <CardContent
          className={'card-detail-card-content'}
          sx={{ display: 'flex', padding: '17px 10px' }}
        >
          <div className={'wrapper-mobile-detail'}>
            <CardMedia
              component="img"
              image={
                `${parseImgURL(leftCardDetailsData?.tp_thumbnail, leftCardDetailsData?.tp_id) ||
                images.Rectangle}`
              }
              className={'img-detail'}
              alt={leftCardDetailsData?.tp_thumbnail || ''}
              sx={{borderRadius: '20px', width:'230px', height:'230px'}}
            />
          </div>

          <CardContent className={'card-detail-card-sub-content'}>
            <div className={"training-name-wrapper"}>
              <Typography className={`training-name ${isTablet ? '' : 'text-truncate'}`}>
                {leftCardDetailsData?.course_category_name}
              </Typography>
            </div>

            <Typography className={`course-name ${isTablet ? '' : 'text-truncate'}`}>
              {leftCardDetailsData?.course_name}
            </Typography>

            <div className={'wrapper-info'}>
              {/*<Typography className={'estimate-duration'}>*/}
              {/*  <TimelapseIcon sx={{ marginBottom: '-7px' }} className={'timelapse'} />{' '}*/}
              {/*  {leftCardDetailsData?.tp_estimation_duration + ' minutes' ?? ' '}.*/}
              {/*</Typography>*/}
              <Typography className={'camp-end-date'}>
                <InsertInvitationIcon
                  sx={{ marginBottom: '-7px', marginRight: '5px' }}
                  className={'insert-invitation'}
                />
                {utcByTimezone ? moment(utcByTimezone).format(FORMAT_DATE.DATE_TIME_ROUND_TRIP): ''}
              </Typography>
            </div>

            <CardActions sx={{ padding: '0' }} className={'card-actions-button-wrapper'}>
              {/*<Button variant="outlined" className={'general-button feedback-on-course-button'}>Feedback On Course</Button>*/}
              <Button
                variant="contained"
                style={(type === TYPE_LETF_CARD_DETAIL.GRADES) ? {} : {display:'none'}}
                disabled={leftCardDetailsData?.has_certificate !== 0 ? false : true}
                className={'general-button get-cert-button'}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  getCertHandler(dispatch, getCertificateEvents, leftCardDetailsData?.phish_id, event);
                }}
              >
                Certificate
              </Button>
            </CardActions>
          </CardContent>
        </CardContent>
      </Card>
      <div className={'mobile-divider'}>
        <Divider light />
      </div>
      <Stack
        direction={'column'}
        className={'course-body'}
        spacing={'32px'}
        sx={{ marginTop: '46px' }}
      >
        <Typography component={'div'}>
          <Typography className={'card-title-overview'}>Course Overview:</Typography>
          <div className={'card-content-overview'} dangerouslySetInnerHTML={{ __html: sanitizeHTML(courseOverview) }}>
          </div>
        </Typography>
        <Typography component={'div'}>
          <Typography className={'card-title-overview'}>Learning Outcomes:</Typography>
          <div className={'card-content-overview'} dangerouslySetInnerHTML={{ __html: sanitizeHTML(learningOutcome) }}>
          </div>
        </Typography>
      </Stack>
      <LoadingPage open={isLoading} />
    </>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {getCourseCatalogue, PaginatedData} from '../api/platform';

export interface ICourseCatalogueData {
  campaign_id: number
  course_category: string
  course_name: string
  end_date: string
  status: string
  tp_thumbnail: string
  tp_id: number
}

export interface GetCourseCatalogueEventsObjectState {
  courseCatalogueData: PaginatedData<ICourseCatalogueData>;
  isLoading: boolean;
  error?: undefined;
}

export interface GetCourseCatalogueEventsParams {
  per_pages: number;
  page: number;
  keyword: string;
  category: string[]
  course_status: string[]
}

const initialState: GetCourseCatalogueEventsObjectState = {
  courseCatalogueData: {
    total_items: 0,
    start_item: 0,
    end_item: 0,
    total_page: 0,
    list: []
  },
  isLoading: false,
  error: undefined
};

export const getCourseCatalogueEvents: any = createAsyncThunk(
  'getCourseCataloguePlatform',async (params: GetCourseCatalogueEventsParams) => {
    const response = await getCourseCatalogue(params);
  return response;
  }
);

export const getCourseCatalogueSlice = createSlice({
  name: 'getCourseCatalogueEvents',
  initialState,
  reducers: {
    setCourseCatalogueEventData: (state, action: PayloadAction<PaginatedData<ICourseCatalogueData>>) => {
      state.isLoading = false;
      state.courseCatalogueData = action.payload;
      state.error = undefined;
    },
    removeCourseCatalogueEventData: (state) => {
      state.isLoading = false;
      state.courseCatalogueData = initialState.courseCatalogueData;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseCatalogueEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCourseCatalogueEvents.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.courseCatalogueData = action.payload;
      state.error = undefined;
    });

    builder.addCase(getCourseCatalogueEvents.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.courseCatalogueData = [];
      state.error = action.payload as string;
    });
  }
});

export const { setCourseCatalogueEventData, removeCourseCatalogueEventData } = getCourseCatalogueSlice.actions;

export const getCourseCatalogueEventsDataSelector = (state: RootState) => state.getCourseCatalogueEvents;
export default getCourseCatalogueSlice.reducer;

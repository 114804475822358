import { FC } from 'react';
import { TimeoutProps } from './Timeout.props';
import './Timeout.css';
import './styles/mobile.css';
import Logout from '../../assets/images/logout-icon.svg';
import { Typography } from '@mui/material';
import { Footer } from '../../container/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import {images as Images} from "../../constants/images";

export const TimeoutPage: FC<TimeoutProps> = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  };
  return (
      <div className={'timeout-page'}>
        <div className="timeout-header">
          <img src={Images.PhriendlyPhishing} alt="Brand of Learner Hub" className="timeout-header-image"/>
        </div>
        <div className={'body'}>
          <div className={'body-content'}>
            <img src={Logout} alt="Timeout icon" className={'logo-timeout'}/>
            <div className={'timeout-whitespace-nowrap'}>
              <Typography className={'title'}>Your session has expired.</Typography>
            </div>
            <Typography className={'guideline-text'}>Click here to login to the</Typography>
            <Typography
                sx={{cursor: 'pointer'}}
                onClick={() => handleClick()}
                className={'portal-name'}
            >
              Learner Hub
            </Typography>
          </div>
        </div>
        <Footer/>
      </div>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getListWorkSpace } from '../api/platform';

export interface IWorkSpaceData {
  cell_id: number | undefined;
  student_id: number | undefined;
  first_name: string;
  last_name: string;
  cell_name: string;
  tz_name: string;
  email: string;
  is_manager: boolean;
}
export interface WorkSpaceEventsObjectState {
  workSpaceData: IWorkSpaceData[];
  isLoading: boolean;
  error?: string;
  workSpaceCurrentData?: IWorkSpaceData;
}

const initialState: WorkSpaceEventsObjectState = {
  workSpaceData: [],
  workSpaceCurrentData: undefined,
  isLoading: false,
  error: undefined
};

export const workSpaceEvents = createAsyncThunk(
  'workSpaceEventPlatform',
  async () => {
    const response = await getListWorkSpace();
    const data = response.data as IWorkSpaceData[];
    return data && data.length > 0
      ? data.map((item) => {
          return {
            cell_id: item.cell_id ?? undefined,
            student_id: item.student_id ?? undefined,
            first_name: item.first_name ?? '',
            last_name: item.last_name ?? '',
            cell_name: item.cell_name ?? '',
            tz_name: item.tz_name ?? '',
            email: item.email ?? '',
            is_manager: item.is_manager ?? false,
          };
        })
      : [];
  }
);

export const workSpaceSlice = createSlice({
  name: 'loginEvents',
  initialState,
  reducers: {
    setWorkSpaceEventData: (state, action: PayloadAction<IWorkSpaceData>) => {
      state.isLoading = false;
      state.workSpaceCurrentData = action.payload;
      state.error = undefined;
    },
    removeWorkSpace: (state) => {
      state.isLoading = false;
      state.workSpaceData = [];
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(workSpaceEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(workSpaceEvents.fulfilled, (state, action: PayloadAction<IWorkSpaceData[]>) => {
      state.isLoading = false;
      state.workSpaceData = action.payload;
      state.error = undefined;
    });

    builder.addCase(workSpaceEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.workSpaceData = [];
      state.error = action.payload as string;
    });
  }
});

export const { setWorkSpaceEventData, removeWorkSpace } = workSpaceSlice.actions;

export const workSpaceEventsDataSelector = (state: RootState) => state.workSpaceEvents;

const workSpaceEventsReducer = workSpaceSlice.reducer;
export default workSpaceEventsReducer;

import React from 'react';
import { Avatar } from '@mui/material';
import { UserAvatarLettersProps } from './UserAvatarLetters.props';
import './UserAvatarLetters.css';
import './styles/mobile.css';
import './styles/ipad.css';

export const UserAvatarLetters: React.FC<UserAvatarLettersProps> = ({ name, classNames }) => {
  const stringColor = (pString: string) => {
    let hash = 0;
    let i;
    for (i = 0; i < pString.length; i += 1) {
      hash = pString.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (userName?: string) => {
    const firstName = userName?.split(' ')[0][0] ? userName?.split(' ')[0][0] : '';
    const lastName = userName?.split(' ')[1][0] ? userName?.split(' ')[1][0] : '';
    const getTwoLetters = firstName + lastName;
    return {
      sx: {
        bgcolor: userName ? stringColor(userName) : '',
        fontSize: '1rem',
        margin: '0 0px 0 7px',
        width: 35,
        height: 35
      },
      children: getTwoLetters
    };
  };
  return <Avatar {...stringAvatar(name)} className={`${classNames}`}/>;
};

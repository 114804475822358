import React from 'react';
import { Amplify } from 'aws-amplify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import LightTheme from './theme/light';
import { Routers } from './routing';
import './assets/styles/ipad.css';
import './assets/styles/mobile.css';
import {configAmplifyParams} from "./utils/util";

Amplify.configure(configAmplifyParams());
const theme = createTheme(LightTheme);

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <main id="main" className="main">
        <Routers />
      </main>
    </ThemeProvider>
  );
};

export default App;

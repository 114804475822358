import React, { useEffect, useState} from 'react';
import { LearnersOverviewProps } from './LearnersOverview.props';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { TabsHeader } from '../../components/TabsHeader';
import { OnBoardingOverview } from '../OnBoardingOverview';
import './LearnersOverview.css';
import './styles/mobile.css';
import { MobileTabs, MobileTabsProps } from '../../components/TabsHeader/components/MobileTabs';
import { CourseResultsOverview } from '../CourseResultsOverview';
import {useAppSelector} from "../../redux/hooks";
import {getStatusLearner, learnersDataSelector} from "../../redux/learnersSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {TAB_HEADER_TITLE} from "../../constants/constants";
import ChangeWorkSpace from "../../hoc/changeWorkSpace";

const LearnersOverviewComponent: React.FC<LearnersOverviewProps> = ({tabIndex, subTabIndex, setSubTabIndex}) => {
  const [headerTitle, setHeaderTitle] = useState( ()=>{
    return (subTabIndex === 0) ? TAB_HEADER_TITLE.POLICY_ACCEPTANCE : TAB_HEADER_TITLE.COURSE_RESULTES;
  });
  const dispatch = useDispatch()<any>;
  const [onBoardingNumber, setOnBoardingNumber] = useState(0)
  const[courseResultNumber, setCourseResultNumber] = useState(0);
  const {statusOverview}= useAppSelector(learnersDataSelector)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getStatusLearner());
  },[dispatch]);

  useEffect(() => {
    setOnBoardingNumber(statusOverview.on_boarding)
    setCourseResultNumber(statusOverview.course_results)
  }, [statusOverview]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const learnerOverviewTabs = [
    { label: TAB_HEADER_TITLE.POLICY_ACCEPTANCE, component: <OnBoardingOverview setOnBoardingNumber={setOnBoardingNumber} />, number: onBoardingNumber },
    { label: TAB_HEADER_TITLE.COURSE_RESULTES, component: <CourseResultsOverview setCourseResultNumber={setCourseResultNumber} />, number: courseResultNumber }
  ];

  const handleChangeTabs = (val: number) => {
    dispatch(getStatusLearner());
    setHeaderTitle(learnerOverviewTabs[val].label);
    setSubTabIndex && setSubTabIndex(val);
    navigate("/courses", {
      state: {
        tabIndex: tabIndex,
        subTabIndex:val
      }
    });
  };

  return (
    <>
      {!isMobile && <h2 className={'tab-header-title'}>{(headerTitle === TAB_HEADER_TITLE.POLICY_ACCEPTANCE ? 'Training Disclosure Acceptance' : headerTitle)}</h2>}
      <Box className={'box-learners-overview'}>
        <TabsHeader
          data={learnerOverviewTabs}
          defaultValue={subTabIndex}
          onChange={handleChangeTabs}
          renderTabs={isMobile ? (props: MobileTabsProps) => <MobileTabs {...props} /> : undefined}
        />
      </Box>
    </>
  );
};
export const LearnersOverview = ChangeWorkSpace(LearnersOverviewComponent);
import { FC } from 'react';
import { LogoutProps } from './Logout.props';
import './Logout.css';
import './styles/mobile.css';
import Logout from '../../assets/images/logout-icon.svg';
import { Typography } from '@mui/material';
import { Footer } from '../../container/Footer/Footer';
import { useNavigate } from 'react-router-dom';

export const LogoutPage: FC<LogoutProps> = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/login');
  };
  return (
    <div className={'logout-page'}>
      <div className={'body'}>
        <div className={'body-content'}>
          <img src={Logout} alt="Logout icon" className={'logo-logout'} />
          <Typography className={'title'}>You have successfully logged out.</Typography>
          <Typography className={'guideline-text'}>Log back in through the</Typography>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick()}
            className={'portal-name'}
          >
            Learner Hub login portal
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
};

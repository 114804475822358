import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getTrainingCourseDetail } from '../api/platform';

export interface ITrainingCourseDetailData {
  tp_id: number;
  tp_name: string;
  tp_thumbnail: string;
  tp_overview: string;
  tp_learning_outcome: string;
  course_id: number;
  course_name: string;
  tp_estimation_duration: number;
  cp_start_date: string;
  cp_end_date: string;
  training_status: number;
  latest_score: string;
  phish_id: number;
  has_certificate: number;
  training_label: string;
  assigned_course_again: number;
  token_1: string;
  token_2: string;
}

export interface trainingCourseDetailsEventsObjectState {
  courseDetails: ITrainingCourseDetailData;
  isLoading: boolean;
  error?: string;
}

const initialState: trainingCourseDetailsEventsObjectState = {
  courseDetails: {
    tp_id: 0,
    tp_name: '',
    tp_thumbnail: '',
    tp_overview: '',
    tp_learning_outcome: '',
    course_id: 0,
    course_name: '',
    tp_estimation_duration: 0,
    cp_start_date: '',
    cp_end_date: '',
    training_status: 0,
    latest_score: '',
    phish_id: 0,
    has_certificate: 0,
    training_label: '',
    assigned_course_again: 0,
    token_1: '',
    token_2: ''
  },
  isLoading: false,
  error: undefined
};

export const trainingCourseDetailEvents = createAsyncThunk(
  'trainingCourseDetailEventPlatform',
  async (params: object) => {
    const response = await getTrainingCourseDetail(params);
    const data = response.data ? response.data : [];
    return data;
  }
);

export const trainingCourseDetailSlice = createSlice({
  name: 'trainingCourseDetailEvents',
  initialState,
  reducers: {
    setTrainingCourseDetailEventData: (state, action: PayloadAction<ITrainingCourseDetailData>) => {
      state.isLoading = false;
      state.courseDetails = action.payload;
      state.error = undefined;
    },
    removeTrainingCourseDetailEvent: (state: any) => {
      state.isLoading = false;
      state.courseDetails = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(trainingCourseDetailEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(trainingCourseDetailEvents.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.courseDetails = action.payload;
      state.error = undefined;
    });

    builder.addCase(trainingCourseDetailEvents.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.courseDetails = undefined;
      state.error = action.payload as string;
    });
  }
});

export const { setTrainingCourseDetailEventData,removeTrainingCourseDetailEvent } = trainingCourseDetailSlice.actions;

export const trainingCourseDetailEventsDataSelector = (state: RootState) =>
  state.trainingCourseDetailEvents;

export default trainingCourseDetailSlice.reducer;

import React, { useEffect, useState } from 'react';
import { CourseProps } from './Courses.props';
import { TabsHeader } from '../../components/TabsHeader/TabsHeader';
import { CardDetail } from '../CardDetail';
import './styles/ipad.css';
import './styles/mobile.css';
import { LearnersOverview } from '../LearnersOverview';
import { Grades } from '../Grades/Grades';
import { useLocation, useNavigate } from 'react-router-dom';
import { workSpaceEventsDataSelector } from '../../redux/workSpaceSlice';
import { checkManagerTabs } from '../../utils/util';
import { useAppSelector } from '../../redux/hooks';
import { useMediaQuery, useTheme } from '@mui/material';
import { OverviewCourseDetail } from '../OverviewCourseDetail';
import './Courses.css';
import { LoadingPage } from '../../components/LoadingPage';
import {CourseCatalogue} from "../CourseCatalogue";

export const Courses: React.FC<CourseProps> = () => {
  const { workSpaceCurrentData, isLoading } = useAppSelector(workSpaceEventsDataSelector);
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const pathSubstrings = pathname.split('/');
  const [isCourseOverview, setIsCourseOverview] = React.useState(false);
  const [isCourseCatalogueOverview, setIsCourseCatalogueOverview] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [subTabIndex, setSubTabIndex] = useState<number>(0);

  useEffect(() => {
    if (state !== null) {
      setTabIndex(state.tabIndex);
      setSubTabIndex(state.subTabIndex);
    }
  }, [state]);

  useEffect(() => {
    setIsCourseOverview(pathSubstrings[2] === 'overview');
    setIsCourseCatalogueOverview(pathSubstrings[2] === 'catalogue-overview');
    }, [pathSubstrings]);

  const handleTabClick = (val: any) => {
    navigate('/courses', {
      state: {
        tabIndex: val,
        subTabIndex: 0
      }
    });
  };

  const dataTabs: any =
    !isLoading && workSpaceCurrentData
      ? [
          {
            label: 'Assigned Learning',
            component: <CardDetail />
          },
        {
          label: 'Course Catalogue',
          component: <CourseCatalogue />
        },
          {
            label: 'Results',
            component: isCourseOverview ? <OverviewCourseDetail /> : <Grades />
          },
          {
            label: 'Team View',
            component: (
              <LearnersOverview
                tabIndex={tabIndex}
                subTabIndex={subTabIndex}
                setSubTabIndex={setSubTabIndex}
              />
            ),
            hidden: !workSpaceCurrentData?.is_manager
          }
        ]
      : [];
  return (
    <>
      {!isCourseCatalogueOverview ? <TabsHeader
        {...checkManagerTabs(dataTabs, tabIndex)}
        onClick={handleTabClick}
        isDropdown={isMobile}
      /> : !isLoading && workSpaceCurrentData && <div style={{margin:'40px 60px'}}><OverviewCourseDetail /></div>}
      <LoadingPage open={isLoading} />
    </>
  );
};

import React from 'react';
import { Dashboard } from '../pages/Dashboard';
import { Courses } from '../pages/Courses/Courses';
import {Header} from "../container/Header";

const routes = [
  {
    component: <><Header /><Dashboard /></>,
    exact: true,
    path: '/dashboard'
  },
  {
    component: <><Header /><Courses /></>,
    exact: true,
    path: '/courses'
  },
  {
    component: <><Header /><Courses /></> ,
    path: '/courses/overview/:id'
  },
  {
    component: <><Header/><Courses/></>,
    path: '/courses/catalogue-overview/:id'
  },
];
export default routes;

import { IWorkSpaceData } from '../redux/workSpaceSlice';
import CourseNonThumbnail from '../assets/images/course-non-thumbnail.svg';
import {AMPLIFY_CONFIG} from "../configs/aws-exports";
export const parseImgURL = (tp_thumbnail?: string, tp_id?: number) => {
  const baseURL = process.env.REACT_APP_PH2_THUMBNAIL_URL;
  let imgURL = CourseNonThumbnail;
  if (tp_thumbnail) {
    imgURL = `${baseURL + '/image_course_management/training_package/thumbnail/' + tp_id + '/' + tp_thumbnail}`;
  }

  return imgURL;
};

export const getCurrentCellStore = () => {
  let currentCellStore: IWorkSpaceData | undefined = undefined;
  const pareCell = localStorage.getItem('currentCell') || '';
  if (pareCell) {
    currentCellStore = JSON.parse(pareCell);
  }

  return currentCellStore;
};

export const getUserName = (cellStore: IWorkSpaceData | undefined) => {
  if (cellStore) {
    return cellStore?.first_name?.trim() + ' ' + cellStore?.last_name?.trim();
  }
  return '';
};

interface IDataTab {
  label: string;
  component: React.ReactNode;
  hidden?: boolean;
}

export const checkManagerTabs = (data: IDataTab[], tabIndex: number) => {
  let result: IDataTab[] = [];
  let tabNumber = 0;
  if (data.length > 0) {
    result = data.filter((x) => !x.hidden);
  }
  if (result.length > tabIndex) {
    tabNumber = tabIndex;
  }

  return { key: result.length, data: result, defaultValue: tabNumber };
};

export const handleStartCourse = (token_1: string, token_2: string) => {
  const baseURL = process.env.REACT_APP_PH2_TRAINING_URL;
  const URL = `${baseURL}?t=${token_1}&p=${token_2}`;
  window.open(URL, '_blank', 'noreferrer');
};

export const getCertHandler = async (
  dispatch: any,
  getCertificateEvents: any,
  phishId: number | undefined,
  e: any
) => {
  e.preventDefault();
  const result = await dispatch(getCertificateEvents(phishId));
  const { payload } = result;
  if (payload && payload.file_data && payload.file_name) {
    downloadBase64File(payload.file_data, payload.file_name);
  }
};

export const downloadBase64File = (base64Data: string, fileName: string) => {
  const linkSource = `data:application/pdf;base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const sanitizeHTML = (html='') => {
  const formattedText = html.replace(/\r\n|\r|\n/g, "<br />");
  // remove <script> and text inside it
  let sanitizedHTML = formattedText.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, '');

  // remove <style> and text inside it
  sanitizedHTML = sanitizedHTML.replace(/<style[^>]*>([\s\S]*?)<\/style>/gi, '');
  return sanitizedHTML;
};

export const configAmplifyParams = () => {
  const isAuthSSO = localStorage.getItem('amplify-redirected-from-hosted-ui')||'';
  const oauthState = sessionStorage.getItem('oauth_state')||'';
  const clientId = localStorage.getItem('client_id') || '';

  if(isAuthSSO || oauthState){
    AMPLIFY_CONFIG.Auth.userPoolWebClientId = clientId;
  } else {
    AMPLIFY_CONFIG.Auth.userPoolWebClientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;
  }
  return AMPLIFY_CONFIG;
};

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface logoutEventsObjectState {
  isLogout: boolean;
  isLoading: boolean;
  error: undefined;
}

const initialState: logoutEventsObjectState = {
  isLogout: false,
  isLoading: false,
  error: undefined
};

export const logoutEvents = createAsyncThunk('logoutEventPlatform', async (isLogout: boolean) => {
  localStorage.clear();
  return isLogout;
});

const logoutSlice = createSlice({
  name: 'logoutEvents',
  initialState,
  reducers: {
    setLogoutEventData: (state: any, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.isLogout = action.payload;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutEvents.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(logoutEvents.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.isLogout = action.payload;
      state.error = undefined;
    });

    builder.addCase(logoutEvents.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.isLogout = false;
      state.error = action.payload as string;
    });
  }
});

export const { setLogoutEventData } = logoutSlice.actions;

export const logoutEventsDataSelector = (state: RootState) => state.logoutEvents;
export default logoutSlice.reducer;

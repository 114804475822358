import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { Footer } from '../container/Footer';
import { LoginPage } from '../pages/Login';
import { LogoutPage } from '../pages/Logout';
import routes from './routes';
import { AuthenticatedStatus } from '../utils/checkUserAuthenticated';
import { useAppDispatch } from '../redux/hooks';
import { notificationActions } from '../redux/notificationsSlice';
import { websocketActions } from '../redux/websocketSlice';
import { PageUnAuthorized } from '../pages/PageUnAuthorized';
import { TimeoutPage } from '../pages/Timeout';
import {AuthRoute} from "../utils/authRoute";
import TimeOutHandler from '../components/TimeoutHandler/TimeoutHandler';
import {Error404} from "../components/Error404";

export default function AppRouter() {
  const dispatch = useAppDispatch();
  const isAuthenticated = AuthenticatedStatus();

  const onMessage = useCallback(
    (message: MessageEvent<any>) => {
      const { type, data } = message.data;
      if (type === 'firebaseToken') {
        dispatch(notificationActions.setFirebaseToken(data));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(websocketActions.startConnecting());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  return (
    <Router>
      <TimeOutHandler />
      <Routes>
        <Route element={<PrivateRoute isLogged={isAuthenticated} />}>

          {routes.map((route, index: number) => {
            return <Route key={index} path={route.path} element={route.component} />;
          })}
        </Route>
        <Route element={<AuthRoute isLogged={isAuthenticated} />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/unauthorized" element={<PageUnAuthorized />} />
          <Route path="/timeout" element={<TimeoutPage />} />
          <Route path="/page404" element={<Error404 />} />
        </Route>
        <Route path="*" element={<NavigateWithRandomRoute isLogged={isAuthenticated} />} />
      </Routes>
      <Footer />
    </Router>
  );
}

const NavigateWithRandomRoute : React.FC<{ isLogged: boolean | undefined }>  = ({isLogged}) => {
  if (!isLogged) {
    return <Navigate to="/login" replace />;
  }
  if (isLogged) {
    return <Navigate to="/dashboard" replace />;
  }
  return <Outlet />
}
const PrivateRoute: React.FC<{ isLogged: boolean | undefined }> = ({ isLogged }) => {
  const isAuth = localStorage.getItem('isAuth') || '';
  const isAuthRedirected = localStorage.getItem('amplify-redirected-from-hosted-ui') || '';
  if (isLogged === false && !isAuthRedirected) {
    return <Navigate to="/login" replace />;
  }
  if (isLogged === undefined && !isAuth && !isAuthRedirected) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
};

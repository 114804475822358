import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { buttonClasses } from '@mui/base/Button';
import { Tab } from '@mui/base/Tab';
import { tabClasses } from '@mui/base/Tab';

import './MobileTabs.css';
import { MobileTabsProps } from './MobileTabs.props';
import { a11yProps } from '../../TabsHeader';

export const MobileTabs: React.FC<MobileTabsProps> = ({ data, handleChange, value }) => {
  return (
    <Tabs value={value} onChange={handleChange}>
      <StyledTabsList>
        {data.map((item, index) => (
          <StyledTab key={index} {...a11yProps(index)}>
            {item.label}{item.number ? ` (${item.number})` : ''}
          </StyledTab>
        ))}
      </StyledTabsList>
    </Tabs>
  );
};

const StyledTab = styled(Tab)`
  color: #00000099;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  padding: 8px 12px;
  border: none;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    border-radius: 24px;
    background-color: #e7ebf0;
    color: #345c8b;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabsList = styled(TabsList)`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

import React, { useEffect, useState } from 'react';
import { WeekCalendarProps } from './WeekCalendar.props';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { images } from '../../constants/images';
import { IconButton } from '@mui/material';
import './WeekCalendar.css';
import { FORMAT_MOMENT } from '../../constants/constants';
import { getCurrentTimeZone } from '../../utils/dateTimeHelper';
import moment from 'moment-timezone';
import './styles/mobile.css';
import { useAppSelector } from '../../redux/hooks';
import { trainingCoursesEventsDataSelector } from '../../redux/trainingCoursesSlice';
import { LoaderIcon } from 'react-hot-toast';
import { trainingCoursesQuantityAllEventsSelector } from '../../redux/trainingCourseQuantityAllSlice';

export const WeekCalendar: React.FC<WeekCalendarProps> = ({
  showDetailsHandle = () => undefined,
  onChangeWeek = () => undefined,
  listCoursePerDay,
  imCompleteDate
}) => {
  const currentTimeZone = getCurrentTimeZone();
  moment.tz.setDefault(currentTimeZone);
  const currentMonth =
    moment(imCompleteDate).month() > 5 ? moment(imCompleteDate).startOf('year').add(6, 'months') : moment(imCompleteDate).startOf('year');

  const [currentMonthChange, setCurrentMonthChange] = useState(currentMonth);
  const { coursesQuantityData = [], isLoadingCoursesQuantity } = useAppSelector(trainingCoursesEventsDataSelector);
  const { isLoadingCoursesQuantityAll } = useAppSelector(trainingCoursesQuantityAllEventsSelector);
  const [coursesList, setCoursesList] = useState(coursesQuantityData || []);
  const [outOfCourse, setOutOfCourse] = useState(0);
  const [selectedDate, setSelectedDate] = useState(imCompleteDate);

  useEffect(()=>{
    const currentMonth =
    moment(imCompleteDate).month() > 5 ? moment(imCompleteDate).startOf('year').add(6, 'months') : moment(imCompleteDate).startOf('year');
    //update tz of currentMonth after get timezone from workspace
    setSelectedDate(imCompleteDate)
    setCurrentMonthChange(currentMonth)
  },[imCompleteDate])

  useEffect(()=>{
    //update tz of currentMonth after get timezone from workspace
    setCurrentMonthChange(currentMonth)
  },[currentTimeZone])

  const changeWeekHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setCurrentMonthChange(moment(currentMonthChange).subtract(6, 'months'));
      const startMonth = moment(currentMonthChange)
        .subtract(6, 'months')
        .utc()
        .format(FORMAT_MOMENT.DATE_TIME_DASH);
      const endMonth = moment(currentMonthChange)
        .subtract(1, 'months')
        .endOf('month')
        .utc()
        .format(FORMAT_MOMENT.DATE_TIME_DASH);
      onChangeWeek(startMonth, endMonth);
    }
    if (btnType === 'next') {
      setCurrentMonthChange(moment(currentMonthChange).add(6, 'months'));
      const startMonth = moment(currentMonthChange)
        .add(6, 'months')
        .utc()
        .format(FORMAT_MOMENT.DATE_TIME_DASH);
      const endMonth = moment(currentMonthChange)
        .add(11, 'months')
        .endOf('month')
        .utc()
        .format(FORMAT_MOMENT.DATE_TIME_DASH);
      onChangeWeek(startMonth, endMonth);
    }
  };

  const handleMonth = (day: any, total: number) => {
    if (total === 0) return;
    const convertDay = moment(day).format();
    setSelectedDate(convertDay);
    const startDate = moment(day).utc().format(FORMAT_MOMENT.DATE_TIME_DASH);
    const endDate = moment(day).endOf('month').utc().format(FORMAT_MOMENT.DATE_TIME_DASH);
    showDetailsHandle(startDate, endDate);
  };

  useEffect(() => {
    setCoursesList(coursesQuantityData);
  }, [coursesQuantityData]);

  useEffect(() => {
    const data = listCoursePerDay?.filter((x) => x.training_status !== 4);
    if (data && data.length) {
      setOutOfCourse(data.length);
    } else {
      setOutOfCourse(0);
    }
  }, [listCoursePerDay]);

  const renderHeader = () => {
    const pastLimit = moment().subtract(1, 'years').startOf('year');
    const futureLimit = moment().startOf('year').add(6, 'months');

    return (
      <div className="header-week-calendar calendar-row flex-middle">
        <div
          className="col col-start"
          style={{
            flexBasis: 'auto',
            display: 'flex',
            alignItems: 'end'
          }}
        >
          <span className={'header-date-label'}>
            {moment(selectedDate).format(FORMAT_MOMENT.MONTH_YEAR)}
          </span>
        </div>
        <div
          className="col col-end"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-start'
          }}
        >
          <IconButton
            sx={{ margin: '15px 0 0 0' }}
            className="icon icon-previous"
            disabled={currentMonthChange.isSame(pastLimit)}
            onClick={() => changeWeekHandle('prev')}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            sx={{ margin: '15px 23px 0 0', marginRight: '18%' }}
            className="icon icon-next"
            disabled={currentMonthChange.isSame(futureLimit)}
            onClick={() => changeWeekHandle('next')}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="header-week-calendar calendar-row flex-middle text-footer">
        <div className={'text-footer'}>
          <img src={images.line1} style={{ verticalAlign: 'middle' }} alt="line" />
          &ensp; &ensp;{outOfCourse ? `${outOfCourse} out of ${listCoursePerDay?.length} courses to complete` : 'No Assigned Courses'}
          &ensp; &ensp;
          <img src={images.line1} style={{ verticalAlign: 'middle' }} alt="line" />
        </div>
      </div>
    );
  };

  const renderMonth = () => {
    const selected = moment(selectedDate).format(FORMAT_MOMENT.MONTH_YEAR);
    const currentMY = moment().startOf('month').format(FORMAT_MOMENT.MONTH_YEAR);

    return (
      <div className="body-calendar-week">
        {((isLoadingCoursesQuantity || isLoadingCoursesQuantityAll)) ? (
          <div className= {`calender-loading`}>
            <LoaderIcon style={{ width: 110, height: 110 }} />
          </div>
        ) : (
          <div className="calendar-row">
            {coursesList.length > 0
              ? coursesList.map((item, index) => {
                  const checkDate = `${moment(item.month.substring(0, 3), 'MMM').format(
                    'MMM'
                  )} ${moment(currentMonthChange).format('YYYY')}`;
                  return (
                    <div
                      key={index}
                      className={`col cell
                     
                      ${checkDate === selected ? 'selected' : ''}
                      ${item.value.total > 0 ? '' : 'non-cursor'}`}
                      onClick={() => handleMonth(moment(checkDate, 'MMM YYYY'), item.value.total)}
                    >
                      <div className={` ${checkDate === currentMY ? 'today' : ''}`}>
                        <div
                            className={`group-week-days ${
                                moment()
                                    .startOf('month')
                                    .isAfter(moment(checkDate, 'MMM YYYY').startOf('month'))
                                    ? 'past-months'
                                    : ''
                            }`}
                        >
                          <div className="week-days">{item.month.substring(0, 3)}</div>
                          <p className="wapper-number" data-testid={"wrapper-number-test"}>
                          <span className="number">
                            {item.value.total}
                            {item.value.has_incomplete === 1 ? (
                                <span className={'point'}></span>
                            ) : null}
                          </span>
                          </p>
                        </div>
                      </div>

                    </div>
                  );
                })
              : null}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderMonth()}
      {renderFooter()}
    </div>
  );
};

import { ReactComponent as HubIcon } from '../assets/images/LearnersHub.svg';
import { ReactComponent as SchoolIcon } from '../assets/images/hat.svg';
export const CONSTANTS = {
  NAVIGATE: {
    HOME: {
      name: 'Hub',
      icon: HubIcon,
      path: '/dashboard'
    },
    COURSES: {
      name: 'Courses',
      icon: SchoolIcon,
      path: '/courses'
    }
  }
};

export const LOGIN_MESSAGES = {
  EMAIL_INCORRECT: 'Inputted Incorrect Email Address',
  EMAIL_DISABLED: 'Your Account is disabled by your admin, contact for them for more Information',
  EMAIL_NOT_REGISTERED:
    'Email Address is not registered in the system, contact your admin for more Information',
  EMAIL_LOCKED: (time:any)=> `Your account has been temporarily locked due to too many failed login attempts. Please wait ${time} and try again.`,
  PASSWORD_WARNING:
    'You have failed to log in 3 times. If you fail to log in 5 times, your account will be locked.',
  PASSWORD_INCORRECT: 'Verification Code is incorrect',
  // EMAIL_EXPIRED: 'Your Company’s account with Phriendly Phishing has expired, contact your admin for support.',
  EMAIL_EXPIRED: (CompanyName: string)=> `${CompanyName} license with Phriendly Phishing has expired. Please contact your company administrator.`,
  EMAIL_NOT_ENABLE_MODULE: 'Your account with Phriendly Phishing does not have Learner Hub Enabled. Please reach out to your internal IT team for more information',
  NOT_EXISTS_COGNITO: 'Account does not exist in Cognito',
  NOT_EXIST_IN_PH2: 'Email address is not registered in the system, contact your admin for more Information',
  EMAIL_NOT_INPUT: 'Please enter a valid email address',
  OTP_HAS_EXPIRED:'Verification Code has expired.'
};
export const KEY_PRESS = {
  NEXT: 'next',
  VERIFY: 'verify'
};

export const DASHBOARD_INTRO = {
  WELCOME: {
    title: 'Phriendly Phishing | welcome',
    author: 'Phriendly Phishing',
    date: '23/03/22',
    content:
      '<div className={"post-content"}>We welcome you to Phriendly Phishing’s Learners hub, this platform is designed to help you with your cyber security education to ensure<br/> you complete your training and are prepared for dealing with Phishing emails that you will encounter on a daily basis.<br/><br/>In the current climate we understand it may be difficult to see what is fake and real; however with Phriendly Phishing’s library of<br/> educational content we can help reduce your chances of falling prey to scammers/criminals by completing training courses set by your<br/> company leaders.',
    isShowAcceptBtn: false
  },
  PRIVACY: {
    title: 'Phriendly Phishing | Training Disclosure Notification',
    author: 'Company Admin',
    date: '23/03/22',
    userName: 'Your business name',
    content:
      '<div class="policy-wrap-content">' +
        '<div class="policy-content">' +
          '<p>Your participation, progress and results obtained during the courses provided by Phriendly Phishing, will be shared and made available to your employer.<br></p>' +
          '<p>Any results or Phriendly Phishing certifications, will also be available for you to access via a Phriendly Phishing Transcript, which can also be accessed upon request to Phriendly Phishing.<br></p>' +
          '<p>If you have left your employment, some results may no longer be available to you, if they are received as a result of sitting customised courses specific to your employer.<br></p>' +
          '<p>All other information that is collected, will be done pursuant and in compliance with the Phriendly Phishing Privacy Policy available <a href="https://www.phriendlyphishing.com/privacy-policy" style="color: #172B52" target="_blank" ><strong>here</strong></a>.<br></p>' +
      '</div>' +
      '</div>',
    isShowAcceptBtn: true
  }
};

export const FORMAT_DATE = {
  DATE: 'dd/MM/yyyy',
  DATE_TIME: 'dd/MM/yyyy HH:mm:ss',
  MONTH_YEAR: 'MMM yyyy',
  DATE_TIME_DASH: 'yyyy-MM-DD HH:mm:ss',
  DATE_TIME_ROUND_TRIP: 'Do MMMM'
};

export const FORMAT_MOMENT = {
  DATE: 'DD/MM/yyyy',
  DATE_TIME: 'DD/MM/yyyy HH:mm:ss',
  MONTH_YEAR: 'MMM YYYY',
  EEE: 'EEE',
  DAY: 'd',
  DATE_NAME: 'ccc dd MMM yy',
  DATE_TIME_DASH: 'YYYY-MM-DD HH:mm:ss'
};
export const HAS_APPROVED = {
  FIRST_TIMES: '0',
  SECOND_TIMES: '1'
};

export const TRAINING_STATUS = {
  TN_FAILED: 1,
  TN_INCOMPLETE: 2,
  TN_NOT_START: 3,
  TN_PASSED: 4
};

export const STATUS = {
  TR_COMPLETED: 1,
  TR_EXPIRED: 2,
  TR_PAUSED: 3,
  TR_PENDING: 4,
  TR_REMOVED: 5,
  TR_RUNNING: 6,
  TR_STOPPED: 8
};
export const STATUS_COURSE = {
  OVERDUE: 'Outstanding',
  ASSIGNED: 'Assigned'
};

export const TYPE_LETF_CARD_DETAIL = {
  GRADES: 'GradesOverview',
};
export const TAB_HEADER_TITLE = {
  POLICY_ACCEPTANCE: 'Training Disclosure',
  COURSE_RESULTES: 'Course Results',
}

export const TOOLTIP_MESSAGES={
  PRIVACY_POLICY_ACCEPTED: 'You have accepted the privacy policy, you now have access to the platform.'
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
  getOnBoardingLearnersResult,
  PaginatedData,
  getLearnerOverviewStatus,
  getCourseFilter,
  getCourseResultTab
} from '../api/platform';
import { GridSortDirection } from '@mui/x-data-grid';
import { IDataFilter } from '../components/SearchMenuBar';

export interface CourseResultState {
  learner_id?: number;
  cell_id?: number;
  per_pages: number;
  page: number;
  keyword: string;
  course: string[];
  training_status: string[];
  sort_type: GridSortDirection;
  sort_column: string;
}

export interface OnBoardingLearner {
  first_name?: string;
  last_name?: string;
  email: string;
  status: boolean;
}

export type TrainingStatus = 'Completed' | 'Failed' | 'Incomplete' | 'Not Started';

export interface CourseResult {
  first_name: string;
  last_name: string;
  email: string;
  course_name: string;
  training_status: TrainingStatus;
  attempts: number;
  grade?: number;
}

export interface OnBoardingParams {
  learner_id?: number;
  cell_id?: number;
  per_pages: number | null;
  keyword: string | null;
  privacy_policy: string[] | null;
  page: number | null;
  sort_type: GridSortDirection | null;
  sort_column: string | null;
}
export interface CourseResultState {
  learner_id?: number;
  cell_id?: number;
  per_pages: number;
  page: number;
  keyword: string;
  course: string[];
  training_status: string[];
}

export interface LearnersState {
  isStatusLoading: boolean;
  isOnBoardingLoading: boolean;
  isCourseLoading: boolean;
  isLoadingCoursesFilter: boolean;
  error: string | undefined;
  onBoardingData: PaginatedData<OnBoardingLearner>;
  courseResultsData: PaginatedData<CourseResult>;
  statusOverview: {
    on_boarding: number;
    course_results: number;
  };
  courses: IDataFilter[];
}

const initialState: LearnersState = {
  isStatusLoading: false,
  isOnBoardingLoading: false,
  isCourseLoading: false,
  isLoadingCoursesFilter: false,
  error: undefined,
  onBoardingData: {
    total_items: 0,
    total_page: 0,
    start_item: 0,
    end_item: 0,
    list: []
  },
  statusOverview: {
    on_boarding: 0,
    course_results: 0
  },
  courseResultsData: {
    total_items: 0,
    total_page: 0,
    start_item: 0,
    end_item: 0,
    list: []
  },
  courses: []
};

export const onBoardingResultEvents = createAsyncThunk(
  'learners/getOnBoarding',
  async (params: OnBoardingParams) => {
    const response: any = await getOnBoardingLearnersResult(params);
    return (
      response ?? {
        end_item: 0,
        start_time: 0,
        total_items: 0,
        total_page: 0,
        list: []
      }
    );
  }
);

export const getCoursesFilterEvent = createAsyncThunk('learner/get_course_filter', async () => {
  const response = await getCourseFilter();
  return response.data ?? [];
});

export const getCourseResultsTabEvent = createAsyncThunk(
  'learner/get_course_results_tab',
  async (params: CourseResultState) => {
    const response: any = await getCourseResultTab(params);
    return (
      response ?? {
        end_item: 0,
        start_time: 0,
        total_items: 0,
        total_page: 0,
        list: []
      }
    );
  }
);
export const getStatusLearner = createAsyncThunk('learners/getStatusOverview', async () => {
  const response = await getLearnerOverviewStatus();
  return (
    response ?? {
      on_boarding: 0,
      course_results: 0
    }
  );
});
export const learnersSlice = createSlice({
  name: 'learners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onBoardingResultEvents.pending, (state) => {
      state.isOnBoardingLoading = true;
    });
    builder.addCase(onBoardingResultEvents.fulfilled, (state, action) => {
      state.isOnBoardingLoading = false;
      state.onBoardingData = action.payload;
      state.error = undefined;
    });
    builder.addCase(onBoardingResultEvents.rejected, (state, action) => {
      state.isOnBoardingLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getStatusLearner.pending, (state) => {
      state.isStatusLoading = true;
    });
    builder.addCase(getStatusLearner.fulfilled, (state, action) => {
      state.isStatusLoading = false;
      state.statusOverview = action.payload;
      state.error = undefined;
    });
    builder.addCase(getStatusLearner.rejected, (state, action) => {
      state.isStatusLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getCourseResultsTabEvent.pending, (state) => {
      state.isCourseLoading = true;
    });
    builder.addCase(getCourseResultsTabEvent.fulfilled, (state, action) => {
      state.isCourseLoading = false;
      state.courseResultsData = action.payload;
      state.error = undefined;
    });
    builder.addCase(getCourseResultsTabEvent.rejected, (state, action) => {
      state.isCourseLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getCoursesFilterEvent.pending, (state) => {
      state.isLoadingCoursesFilter = true;
    });
    builder.addCase(getCoursesFilterEvent.fulfilled, (state: any, action: any) => {
      state.isLoadingCoursesFilter = false;
      state.courses = action.payload.map((item: any, index: number) => ({
        id: index,
        content: item
      }));
      state.error = undefined;
    });
    builder.addCase(getCoursesFilterEvent.rejected, (state: any, action: any) => {
      state.isLoadingCoursesFilter = false;
      state.courses = undefined;
      state.error = action.payload as string;
    });
  }
});

export const learnersActions = learnersSlice.actions;

export const learnersDataSelector = (state: RootState) => state.learnersEvents;

export default learnersSlice.reducer;
